import React, { useContext, useEffect, useState } from 'react';
// AUTH
import { AuthContext } from '../contexts/AuthContext';
// REACT ROUTER
import { useNavigate, useLocation, redirect } from 'react-router-dom';
// AXIOS
import axios from 'axios';
// REACT HOOK FORM
import { useForm, useFieldArray } from "react-hook-form";
// BOOTSTRAP
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
// REACT SPINNER
import { Watch } from 'react-loader-spinner';
// REACT SWEETALERT
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
// DYNAMIC ICON
import DynamicIcon from '../partials/DynamicIcon';

function EvaluationsAss() {
  // AUTH, STATE, NAVIGATION
  const { authobj } = useContext(AuthContext);
  const { state } = useLocation();
  let navigate = useNavigate();
  // FORM
  const { register, control, handleSubmit, trigger, getValues, setValue, reset, formState: { errors } } = useForm();
  // SWEETALERT
  const MySwal = withReactContent(Swal);
  // DATA
  const [evaluation, setEvaluation] = useState(null);
  const [technicians, setTechnicians] = useState([]);
  // SPINNER
  const [wait, setWait] = useState(false);
  const [waitPercent, setWaitPercent] = useState(null);

  useEffect(() => {
    setWait(true);

    // Dato che sto modificando una pratica esistente, carico le informazioni relative
    if (state && state.evaluation_id) {
      axios.post(window.SERVER_URL+'evaluations/get', { id: state.evaluation_id }, { headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer '+authobj.token
      }}).then(res => {
        console.log(res.data);
        setEvaluation(res.data.evaluation);

        // Carico i tecnici
        axios.post(window.SERVER_URL+'technicians/list', { params: { evaluation_id: state.evaluation_id } }, { headers: {
          'Access-Control-Allow-Origin': '*',
          'Authorization': 'Bearer '+authobj.token
        }}).then(rest => {
          setTechnicians(rest.data.technicians);
          setWait(false);
        });
      });
    } else {
      setWait(false);
    }
  }, [reset]);
  
  // Submit della form
  const onSubmit = (form_data) => {
    console.log(form_data);
       
    axios.post(window.SERVER_URL+'evaluations/assign', form_data, { headers: {
      'Access-Control-Allow-Origin': '*',
      'Authorization': 'Bearer '+authobj.token
    }}).then(res => {
      if (res.data.success === true) {
        setWait(false);
        Swal.fire({
          html: `<img src="${process.env.PUBLIC_URL}/gfx/Raggruppa138.png"><br><b>Ottimo lavoro!</b><br>La pratica con ID ${evaluation.id}<br>è stata assegnata con successo.`,
          showDenyButton: false,
          showCancelButton: false,
          confirmButtonText: "Torna alla dashboard",
          confirmButtonColor: "#12275d"
        }).then((result) => {
          if (result.isConfirmed) { // carica nuova pratica
            navigate('/evaluations');
          }
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Errore',
          text: "Non è stato possibile assegnare la pratica"
        });
      }
    }).catch(function(error) {
      console.log(error);
      Swal.fire({
        icon: 'error',
        title: 'Errore',
        text: "Non è stato possibile assegnare la pratica"
      });
    });
  }

  return (
    <>
      { wait ? 
        <div id="wait">
          { waitPercent !== null ?
            <div className="percent">{waitPercent}%</div>
          : '' }
          <Watch height="80" width="80" radius="48" color="#44B2EB" ariaLabel="watch-loading" wrapperStyle={{}} wrapperClassName="" visible={true} />
        </div>
      : '' }
      <Container className="content">
        <Row className="page_title">
          <Col>
            <h1 style={{'textAlign':'left'}}>
              Assegnazione pratica:
              <span className='bordered_info full'>{ evaluation ? evaluation.id : '' }</span>
            </h1>
          </Col>
        </Row>
        <Row>
          <Col className="custom_form_wrapper det_wrapper">
            <form className="custom_form" onSubmit={handleSubmit(onSubmit)}>
              <input type="hidden" name="evaluation_id" {...register("evaluation_id")} value={ state.evaluation_id ? state.evaluation_id : null } />
              <div id="tech_info">
                <label className='step_label big'>Assegna a un tecnico</label>
                <Table responsive>
                  <thead>
                    <tr>
                      <th></th>
                      <th>Nome</th>
                      <th>Sopralluoghi<br/><b>Non fissati</b></th>
                      <th>Sopralluoghi<br/><b>Fissati</b></th>
                      <th>Sopralluoghi<br/><b>Effettuati</b></th>
                      <th>Incarichi<br/><b>Sospesi</b></th>
                      <th>Incarichi<br/><b>Chiusi</b></th>
                      <th>Incarichi<br/><b>In ritardo</b></th>
                      <th style={{'width':'120px'}}>Dettaglio</th>
                    </tr>
                  </thead>
                  <tbody>
                    { technicians && technicians.length ? technicians.map((tech) => 
                      <tr>
                        <td><input {...register("user_id")} type="radio" value={tech.id} className='noMargin' /></td>
                        <td>{ tech.nome } { tech.cognome }</td>
                        <td><b>{ tech.widgets ? tech.widgets.assegnate : '' }</b></td>
                        <td><b>{ tech.widgets ? tech.widgets.sopralluoghi_fissati : '' }</b></td>
                        <td><b>{ tech.widgets ? tech.widgets.sopralluoghi_effettuati : '' }</b></td>
                        <td><b>{ tech.widgets ? tech.widgets.sospese : '' }</b></td>
                        <td><b>{ tech.widgets ? tech.widgets.completate : '' }</b></td>
                        <td style={{'color':'#d90000'}}><b>{ tech.widgets ? tech.widgets.ritardo : '' }</b></td>
                        <td className="actions">
                          <Button title="Vedi dettaglio tecnico" onClick={() => { navigate('/technicians/add', {state: { technician_id: tech.id }}) }}><DynamicIcon iconName="FaEye" /></Button>
                        </td>
                      </tr>
                    ) : '' }
                  </tbody>
                </Table>
              </div>
              <Row>
                <Col>
                  <Button type="submit" variant="primary" className="waste_btn">Assegna pratica</Button>
                </Col>
              </Row>
            </form>
          </Col>
        </Row>
        <br /><hr />
        <Row className="action_bar bottom">
          <Col>
            <Button onClick={() => { navigate('/evaluations', { state: { page: (state && state.page ? state.page : 1), query: (state && state.query ? state.query : null) } }); }}>
              <DynamicIcon iconName='IoIosArrowBack' /> Torna alle pratiche
            </Button> 
          </Col>
        </Row>
      </Container>
    </>
  );
  
}

export default EvaluationsAss;