import React, { useContext, useEffect, useState } from 'react';
// AUTH
import { AuthContext } from '../contexts/AuthContext';
// REACT ROUTER
import { useNavigate, useLocation } from 'react-router-dom';
// AXIOS
import axios from 'axios';
// REACT HOOK FORM
import { useForm } from "react-hook-form";
// BOOTSTRAP
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
// REACT SPINNER
import { Watch } from 'react-loader-spinner';
// REACT SWEETALERT
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
// DYNAMIC ICON
import DynamicIcon from '../partials/DynamicIcon';

function RolesAdd() {
  // AUTH, STATE, NAVIGATION
  const { authobj } = useContext(AuthContext);
  const { state } = useLocation();
  let navigate = useNavigate();
  // FORM
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  // SWEETALERT
  const MySwal = withReactContent(Swal);
  // DATA
  const [role, setRole] = useState(null);
  // SPINNER
  const [wait, setWait] = useState(false);
  const [waitPercent, setWaitPercent] = useState(null);

  useEffect(() => {
    setWait(true);

    // Se sto modificando un ruolo esistente, carico le informazioni relative
    if (state && state.role_id) {
      axios.post(window.SERVER_URL+'roles/get', { id: state.role_id }, { headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer '+authobj.token
      }}).then(res => {
        console.log(res.data);
        setRole(res.data.role);          

        // Setto i campi della form
        let fields = {};
        for (const [key, val] of Object.entries(res.data.role))
          if (typeof val !== 'object')
            fields[key] = val;
        reset(fields);

        setWait(false);
      });
    } else {
      setWait(false);
    }
  }, [reset]);
  
  // Submit della form
  const onSubmit = (form_data) => {
    console.log(form_data);
    axios.post(window.SERVER_URL+'roles/save', form_data, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer '+authobj.token,
      }
    }).then(res => {
      Swal.fire({
        icon: 'success',
        title: 'Ruolo salvato',
        text: 'Ruolo salvato correttamente'
      }).then((result) => {
        if (result.isConfirmed) {
          navigate('/roles');
        }
      });
    }).catch(function(error) {
      console.log(error);
      Swal.fire({
        icon: 'error',
        title: 'Errore',
        text: "Non è stato possibile salvare il ruolo"
      });
    });
  }

  return (
    <>
      { wait ? 
        <div id="wait">
          { waitPercent !== null ?
            <div className="percent">{waitPercent}%</div>
          : '' }
          <Watch height="80" width="80" radius="48" color="#44B2EB" ariaLabel="watch-loading" wrapperStyle={{}} wrapperClassName="" visible={true} />
        </div>
      : '' }
      <Container className="content">
        <Row className="page_title">
          <Col>
            <h1>Aggiungi o modifica ruolo</h1>
          </Col>
        </Row>
        <Row>
          <Col className="custom_form_wrapper">
            <form className="custom_form" onSubmit={handleSubmit(onSubmit)}>
              <Row>
                <Col>
                  <label>Nome</label>
                  <input type="text" placeholder="Nome" {...register("descrizione", { required: true })} />
                  {errors.nome && <span>Devi inserire un nome</span>}
                </Col>
                <Col>
                  <label>Slug</label>
                  <input type="text" placeholder="Slug" {...register("slug")} />
                </Col>
              </Row>
              <Button type="submit" variant="primary" className="waste_btn">Salva</Button>
            </form>
          </Col>
        </Row>
        <Row className="action_bar bottom">
          <Col>
            <Button onClick={() => navigate('/roles', { state: { page: (state && state.page ? state.page : 1), query: (state && state.query ? state.query : null) } })}><DynamicIcon iconName='IoIosArrowBack' /> Torna ai ruoli</Button>
          </Col>
        </Row>
      </Container>
    </>
  );
  
}

export default RolesAdd;