import React, { useEffect, useState, useRef } from 'react';
// BOOTSTRAP
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

function Map({ center, zoom, height, width, checks, noControls, noDrag, newcoords, fitBounds }) {
  // window.points = [];
  const ref = useRef();
  const [numSelectedJobs, setNumSelectedJobs] = useState(0);

  const calcDistance = (coords1, coords2) => {
    const earthRadiusKm = 6371;
  
    const dLat = (coords2[0] - coords1[0]) * Math.PI / 180;
    const dLon = (coords2[1] - coords1[1]) * Math.PI / 180;
  
    const lat1 = coords1[0] * Math.PI / 180;
    const lat2 = coords2[0] * Math.PI / 180;
  
    const a = Math.sin(dLat/2) * Math.sin(dLat/2) +
            Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1) * Math.cos(lat2); 
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
    const d = earthRadiusKm * c;
  
    return d;
  }

  const handlePoint = (mark, not_remove) => {
    var routePoints = JSON.parse(localStorage.getItem('routePoints')) || [];

    // console.log("PREV SELECTED JOBS:");
    // console.log(routePoints);
    let point_to_handle = mark.get('id');
    if (!routePoints.includes(point_to_handle)) {
      routePoints = [...routePoints, point_to_handle];  

      mark.setIcon(customIcon({
        fillColor: window.MARKER_COLORS['checked'],
        strokeColor: 'white'
      }));
      // console.log("Il punto "+point_to_handle+" è stato aggiunto");
      // console.log("NEW SELECTED JOBS:");
      // console.log(routePoints);
    } else {
      // if (!not_remove) {  // solo se non ho settato il flag "not_remove"
        // let tmp_points = routePoints.filter(point => point_to_handle !== point);
        // routePoints = tmp_points;

        // mark.setIcon(customIcon({
        //   fillColor: window.MARKER_COLORS[mark.get('priority')],
        //   strokeColor: 'white'
        // }));
        // console.log("Il punto "+point_to_handle+" era già presente, è stato tolto");
        // console.log("NEW SELECTED JOBS:");
        // console.log(routePoints);
      // }
    }

    // setNumSelectedJobs(routePoints.length);
    // localStorage.setItem('routePoints', JSON.stringify(routePoints));
  }

  const customIcon = (opts) => Object.assign({
    path: 'M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z',
    fillColor: '#34495e',
    fillOpacity: 1,
    strokeColor: '#000',
    strokeWeight: 1,
    scale: 1,
  }, opts);

  const startOptimize = () => {
    var routePoints = JSON.parse(localStorage.getItem('routePoints')) || [];
  }

  var icon = {
    url: `${process.env.PUBLIC_URL}/gfx/marker.png`,
    scaledSize: new window.google.maps.Size(30, 45), // scaled size
    origin: new window.google.maps.Point(0, 0), // origin
    anchor: new window.google.maps.Point(16, 45) // anchor
};

  useEffect(() => {
    var directionsService = new window.google.maps.DirectionsService();
    // var directionsRenderer = new window.google.maps.DirectionsRenderer();
    var map = new window.google.maps.Map(ref.current, { center, zoom });
    // directionsRenderer.setMap(map);

    // posiziono sulla mappa tutti i marker relativi agli interventi da effettuare
    if (checks) {
      var checkpoints = {};
      let ar_checkpoints = [];
      let ar_coords = [];
      checks.forEach(function(check, index) {
        let latlng = new window.google.maps.LatLng(check.lat, check.lng);
        checkpoints[index] = new window.google.maps.Marker({
          position: latlng,
          map,
          // title: check.name+" - "+check.address,
          draggable: (noDrag === true ? false : true),
          icon: icon
        });
        // checkpoints[index].setIcon(customIcon({
        //   fillColor: window.MARKER_COLORS[check.priority],
        //   strokeColor: 'white'
        // }));
        checkpoints[index].set('id', check.id);
        checkpoints[index].set('priority', check.priority);

        ar_checkpoints.push(checkpoints[index]);
        ar_coords.push(latlng);

        // window.google.maps.event.addListener(checkpoints[index], 'click', function() {      
        //   handlePoint(this, false);    // il flag true serve per fare in modo che se il punto già esiste non va tolto
          // if (this.getIcon().fillColor != window.MARKER_COLORS['checked']) {
            // se il punto non era già selezionato lo aggiungo
            // addSelectedPoint(this);
          // } else {
            // se invece era selezionato lo rimuovo
            // removeSelectedPoint(this);
          // }
        // });

        window.google.maps.event.addListener(checkpoints[index], 'dragend', function() {
          let newpos = { lat: parseFloat(checkpoints[index].getPosition().lat().toFixed(6)), lng: parseFloat(checkpoints[index].getPosition().lng().toFixed(6)) };
          console.log(newpos);
          if (newcoords)
            newcoords(newpos);
        });
      });

      // if (fitBounds !== false) {
      //   var bounds = new window.google.maps.LatLngBounds();
      //   for (var i = 0; i < ar_checkpoints.length; i++)
      //     bounds.extend(ar_checkpoints[i].getPosition());
      //   map.fitBounds(bounds);
      //   map.setCenter(bounds.getCenter());
      //   map.setZoom(map.getZoom()-1); 
      // }
    }

    
  }, [ref, checks]);
  
  return (
    <>
      <div ref={ref} id="map" style={{ height, width }} />
      { noControls !== true ?
      <>
        <br /><hr />
        <Container className="content noMargin">
          <Row>
            <Col style={{ 'textAlign':'center' }}>
              <p>Hai selezionato <b>{ numSelectedJobs }</b> interventi</p>
              <Button variant="primary" className="waste_btn" style={{ 'display':'block','width':'320px' }} onClick={() => { startOptimize(); }}>Ottimizza percorso</Button>
            </Col>
          </Row>
        </Container>
      </>
      : '' }
    </>
  );
};

export default Map;