import React, { useState, useEffect, useContext } from 'react';
// AUTH
import { AuthContext } from '../contexts/AuthContext';
// REACT ROUTER
import { useNavigate, useLocation } from 'react-router-dom';
// AXIOS
import axios from 'axios';
// BOOTSTRAP
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
// REACT SWEETALERT
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
// DYNAMIC ICON
import DynamicIcon from '../partials/DynamicIcon';

function Roles() {
  // AUTH, STATE, NAVIGATION
  const { authobj } = useContext(AuthContext);
  const { state } = useLocation();
  let navigate = useNavigate();
  // SWEETALERT
  const MySwal = withReactContent(Swal);
  // DATA
  const [roles, setRoles] = useState(null);
  const [page, setPage] = useState(1);
  const [numPages, setNumPages] = useState(1);
  const [count, setCount] = useState(null);
  const [query, setQuery] = useState(null);

  useEffect(() => {
    // document.getElementById('query').value = state && state.query ? state.query : query;
    getPage(
      state && state.page ? state.page : page,
      state && state.query ? state.query : query
    );
  }, []);

  const getPage = (i, query) => {
    let params = {
      page: i
    }
    if (query && query.length >= 3)
      params.search = query;
      
    axios.post(window.SERVER_URL+'roles/list', { params }, { headers: {
      'Access-Control-Allow-Origin': '*',
      'Authorization': 'Bearer '+authobj.token
    }}).then(res => {
      console.log(res.data);
      setRoles(res.data.roles);
      setPage(i);
      setNumPages(res.data.pages);
      setCount(res.data.count);
      setQuery(query);
    });
  }

  const handleInputChange = (typed) => {
    console.log(typed);
    getPage(1, typed);
  }

  const handleDelete = (role_id) => { 
  }
  
  return (
    <>
      <Container className="content">
        <Row className="page_title">
          <Col>
            <h1>Ruoli</h1>
          </Col>
        </Row>
        <Row className="action_bar top">
          <Col>
            <Button variant="success" onClick={() => { navigate('/roles/add', { state: { page: page, query: query } }); }}><DynamicIcon iconName='BiPlus' /> Aggiungi ruolo</Button>
          </Col>
        </Row>
        {/* <Row>
          <Col style={{margin:'0 20px'}}>
            <form className="custom_form" id="query_form">
              <DynamicIcon iconName='FaSearch' />
              <input type="text" placeholder="Cerca ruolo" id="query" onChange={(e) => { handleInputChange(e.target.value) }} />
            </form>
          </Col>
        </Row> */}
        <Row>
          <Col className='tab_wrapper'>
            { roles ? 
              <>
                <Table responsive>
                  <thead>
                    <tr>
                      <th style={{'width':'100px'}}>ID</th>
                      <th>Nome</th>
                      <th style={{'width':'120px'}}></th>
                    </tr>
                  </thead>
                  <tbody>
                    { roles.length ? roles.map((role) => {
                        return <tr key={ role.id }>
                                <td>{ role.id }</td>
                                <td>{ role.descrizione }</td>
                                <td className="actions">
                                  <Button onClick={() => { navigate('/roles/add', { state: { role_id: role.id, page: page, query: query } }) }} title="Modifica ruolo"><DynamicIcon iconName='IoIosArrowForward' /></Button>
                                  <Button onClick={() => { navigate('/permissions', { state: { role_id: role.id, page: page, query: query } }) }} title ="Modifica permessi"><DynamicIcon iconName='FaListUl' /></Button>
                                  {/* <Button onClick={() => { handleDelete(user.id) }} className="delete"><DynamicIcon iconName='FaTrash' /></Button> */}
                                </td>
                              </tr>
                      }) : '' }
                  </tbody>
                </Table>
                { numPages > 1 ?
                  <div className='pageListWrapper'>
                    <ul className='pagesList'>
                      { [...Array(numPages)].map(function(obj, i) {
                        let ii = i+1;
                        if (numPages > 5)
                          switch (parseInt(ii)) {
                            case 1:
                            case (page-1):
                            case page:
                            case page+1:
                            case numPages:
                              return <li key={'pag'+ii} className={ ii == page ? 'active' : '' } onClick={() => { getPage(ii, query) }}>{ii}</li>
                            default:
                              return '';
                          }
                        else
                          return <li key={'pag'+ii} className={ ii == page ? 'active' : '' } onClick={() => { getPage(ii, query) }}>{ii}</li>
                      }) }
                    </ul>
                  </div>
                : '' }
              </>
            : <p style={{textAlign:'center'}}>Nessun ruolo presente</p> }
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Roles;