import React, { useState, useEffect, useContext } from 'react';
// AUTH
import { AuthContext } from '../contexts/AuthContext';
// REACT ROUTER
import { useNavigate, useLocation } from 'react-router-dom';
// AXIOS
import axios from 'axios';
// BOOTSTRAP
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
// TOLTIP
import { Tooltip } from 'react-tooltip';
// REACT SWEETALERT
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
// DYNAMIC ICON
import DynamicIcon from '../partials/DynamicIcon';

function Technicians() {
  // AUTH, STATE, NAVIGATION
  const { authobj } = useContext(AuthContext);
  const { state } = useLocation();
  let navigate = useNavigate();
  // SWEETALERT
  const MySwal = withReactContent(Swal);
  // DATA
  const [technicians, setTechnicians] = useState(null);
  const [page, setPage] = useState(1);
  const [numPages, setNumPages] = useState(1);
  const [count, setCount] = useState(null);
  const [query, setQuery] = useState(null);
  const cases = {
    0: {
     'action': 'disattivare',
     'btn': 'Disattiva',
     'done': 'disattivato',
     'class': 'deactivated'
    },
    1: {
     'action': 'attivare',
     'btn': 'Attiva',
     'done': 'attivato',
     'class': 'activated'
    },
    2: {
     'action': 'sospendere',
     'btn': 'Sospendi',
     'done': 'sospeso',
     'class': 'suspanded'
    }
  };

  useEffect(() => {
    // document.getElementById('query').value = state && state.query ? state.query : query;
    getPage(
      state && state.page ? state.page : page,
      state && state.query ? state.query : query
    );
  }, []);

  const getPage = (i, query) => {
    let params = {
      page: i
    }
    if (query && query.length >= 3)
      params.search = query;
      
    axios.post(window.SERVER_URL+'technicians/list', { params }, { headers: {
      'Access-Control-Allow-Origin': '*',
      'Authorization': 'Bearer '+authobj.token
    }}).then(res => {
      console.log(res.data);
      setTechnicians(res.data.technicians);
      setPage(i);
      setNumPages(res.data.pages);
      setCount(res.data.count);
      setQuery(query);
    });
  }

  const handleInputChange = (typed) => {
    console.log(typed);
    getPage(1, typed);
  }

  const handleDelete = (technician_id) => {
    Swal.fire({
      icon: 'warning',
      title: "Vuoi davvero cancellare il tecnico?",
      html: "<b>ATTENTO!</b> L'operazione non è reversibile.",
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: 'Cancella',
      // denyButtonText: 'Annulla',
      cancelButtonText: 'Annulla',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
    }).then((result) => {
      if (result.isConfirmed) {
        axios.post(window.SERVER_URL+'technicians/delete', { id: technician_id }, { headers: {
          'Access-Control-Allow-Origin': '*',
          'Authorization': 'Bearer '+authobj.token
        }}).then(res => {
          if (res.data.success === true) {
            getPage(1, query);
            Swal.fire({
              icon: 'success',
              title: 'Tecnico cancellato',
              text: 'Tecnico cancellato correttamente'
            });
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Errore',
              text: "Non è stato possibile cancellare il tecnico"
            });
          }
        });
      }
    });
  }

  const handleChangeState = (technician_id, cod_state) => {
    if (cod_state == 'susp') {
      Swal.fire({
        icon: 'warning',
        title: 'Errore',
        text: "Per gestire la sospensione di un tecnico devi usare i comandi appositi"
      });
    } else {
      let swal_options = {
        icon: 'warning',
        title: "Vuoi davvero "+cases[cod_state].action+" il tecnico?",
        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: cases[cod_state].btn,
        // denyButtonText: 'Annulla',
        cancelButtonText: 'Annulla',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33'
      }
      if (cod_state == 2) {
        swal_options['html'] = "Puoi inserire una motivazione che verrà mostrata in un popup quando il tecnico farà il login sulla piattaforma";
        swal_options['input'] = "textarea";
        swal_options['inputLabel'] = "Motivazione della sospensione";
      }

      Swal.fire(swal_options).then((result) => {
        console.log(result);
        if (result.isConfirmed) {
          axios.post(window.SERVER_URL+'users/change_state', { id: technician_id, stato: cod_state, stato_note: (typeof result.value === 'string' ? result.value : null) }, { headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization': 'Bearer '+authobj.token
          }}).then(res => {
            if (res.data.success === true) {
              // setTechnicians(technicians.filter(technician => technician.id !== technician_id));
              getPage(1, query);
              Swal.fire({
                icon: 'success',
                title: 'Tecnico '+cases[cod_state].done,
                text: 'Tecnico '+cases[cod_state].done+' correttamente'
              });
            } else {
              Swal.fire({
                icon: 'error',
                title: 'Errore',
                text: "Non è stato possibile "+cases[cod_state].action+" il tecnico"
              });
            }
          });
        }
      });
    }
  }
  
  return (
    <>
      <Container className="content">
        <Row className="page_title">
          <Col>
            <h1>Tecnici</h1>
          </Col>
        </Row>
        <Row className="action_bar top">
          <Col>
            <Button variant="success" onClick={() => { navigate('/technicians/add', {state: {page: page, query: query}}); }}><DynamicIcon iconName='BiPlus' /> Nuovo tecnico</Button>
          </Col>
        </Row>
        {/* <Row>
          <Col style={{margin:'0 20px'}}>
            <form className="custom_form" id="query_form">
              <DynamicIcon iconName='FaSearch' />
              <input type="text" placeholder="Cerca tecnico" id="query" onChange={(e) => { handleInputChange(e.target.value) }} />
            </form>
          </Col>
        </Row> */}
        <Row>
          <Col className='tab_wrapper'>
            { technicians ? 
              <>
                <Table responsive>
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Nome</th>
                      <th>P.IVA</th>
                      <th>Cod.fiscale</th>
                      <th>Comune</th>
                      <th>E-mail</th>
                      <th>Data registrazione</th>
                      <th>Stato</th>
                      <th style={{'width':'170px'}}></th>
                    </tr>
                  </thead>
                  <tbody>
                    { technicians.length ? technicians.map((technician) => {
                        return <tr key={ technician.id }>
                                <td>{ technician.id }</td>
                                <td>{ technician.nome+' '+technician.cognome }</td>
                                <td>{ technician.profile.partita_iva }</td>
                                <td>{ technician.profile.codice_fiscale }</td>
                                <td>{ technician.profile.comune }</td>
                                <td>{ technician.email }</td>
                                <td>{ technician.created_at ? technician.created_at.substr(0, 10).split("-").reverse().join("/") : '-' }</td>
                                <td><div className={ 'item_state '+cases[technician.stato].class } title={ cases[technician.stato].done } onClick={() => { handleChangeState(technician.id, (technician.stato == 0 ? 1 : (technician.stato == 1 ? 0 : 'susp'))) }}></div></td>
                                <td className="actions">
                                  <Button className='tech_det' onClick={() => { navigate('/technicians/add', {state: {technician_id: technician.id, page: page, query: query}}) }}>
                                    <DynamicIcon iconName='IoIosArrowForward' />
                                    <Tooltip anchorSelect=".tech_det" place="top" variant="info">Vedi profilo tecnico</Tooltip>
                                  </Button>
                                  { technician.stato == 1 ?
                                    <Button onClick={() => { handleChangeState(technician.id, 2) }} className="suspend">
                                      <DynamicIcon iconName='FaPause' />
                                      <Tooltip anchorSelect=".suspend" place="top" variant="info">Sospendi tecnico</Tooltip>
                                    </Button>
                                  : technician.stato == 2 ?
                                    <Button onClick={() => { handleChangeState(technician.id, 1) }} className="activate">
                                      <DynamicIcon iconName='FaCheck' />
                                      <Tooltip anchorSelect=".activate" place="top" variant="info">Attiva tecnico</Tooltip>
                                    </Button>
                                  : '' }
                                  <Button onClick={() => { handleDelete(technician.id) }} className="delete">
                                    <DynamicIcon iconName='FaTrash' />
                                    <Tooltip anchorSelect=".delete" place="top" variant="info">Cancella tecnico</Tooltip>
                                  </Button>
                                </td>
                              </tr>
                      }) : '' }
                  </tbody>
                </Table>
                { numPages > 1 ?
                  <div className='pageListWrapper'>
                    <ul className='pagesList'>
                      { [...Array(numPages)].map(function(obj, i) {
                        let ii = i+1;
                        if (numPages > 5)
                          switch (parseInt(ii)) {
                            case 1:
                            case (page-1):
                            case page:
                            case page+1:
                            case numPages:
                              return <li key={'pag'+ii} className={ ii == page ? 'active' : '' } onClick={() => { getPage(ii, query) }}>{ii}</li>
                            default:
                              return '';
                          }
                        else
                          return <li key={'pag'+ii} className={ ii == page ? 'active' : '' } onClick={() => { getPage(ii, query) }}>{ii}</li>
                      }) }
                    </ul>
                  </div>
                : '' }
              </>
            : <p style={{textAlign:'center'}}>Nessuna anagrafica presente</p> }
          </Col>
        </Row>
      </Container>
    </>
  );
  
}

export default Technicians;