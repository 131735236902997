import React, { useContext, useEffect, useState } from 'react';
// AUTH
import { AuthContext } from '../contexts/AuthContext';
// REACT ROUTER
import { useNavigate, useLocation } from 'react-router-dom';
// AXIOS
import axios from 'axios';
// REACT HOOK FORM
import { useForm } from "react-hook-form";
// BOOTSTRAP
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
// REACT SPINNER
import { Watch } from 'react-loader-spinner';
// DYNAMIC ICON
import DynamicIcon from '../partials/DynamicIcon';

function ExportInvoiceable() {
  // AUTH, STATE, NAVIGATION
  const { authobj } = useContext(AuthContext);
  const { state } = useLocation();
  let navigate = useNavigate();
  // FORM
  const { register, handleSubmit, reset, getValues, formState: { errors } } = useForm();
  // SPINNER
  const [wait, setWait] = useState(false);
  const [waitPercent, setWaitPercent] = useState(null);

  useEffect(() => {
    
  }, [reset]);

  
  // Submit della form
  const onSubmit = (form_data) => {
    console.log(form_data);
    window.location.href=`${window.SERVER_URL}evaluations/export_invoiceable?data_inizio=${form_data.date_from}&data_fine=${form_data.date_to}`;
  }

  return (
    <>
      { wait ? 
        <div id="wait">
          { waitPercent !== null ?
            <div className="percent">{waitPercent}%</div>
          : '' }
          <Watch height="80" width="80" radius="48" color="#44B2EB" ariaLabel="watch-loading" wrapperStyle={{}} wrapperClassName="" visible={true} />
        </div>
      : '' }
      <Container className="content">
        <Row className="page_title">
          <Col>
            <h1>Esporta pratiche per fatturazione</h1>
          </Col>
        </Row>
        <Row>
          <Col className="custom_form_wrapper">
            <form className="custom_form" onSubmit={handleSubmit(onSubmit)}>
              <Row>
                <Col lg={2}>
                  <label>Data inizio periodo</label>
                  <input type="date" placeholder="Data inizio" {...register("date_from", { required: true })} />
                  {errors.nome && <span>Devi inserire una data di inizio</span>}
                </Col>
                <Col lg={2}>
                  <label>Data fine periodo</label>
                  <input type="date" placeholder="Data fine" {...register("date_to", { required: true })} />
                  {errors.cognome && <span>Devi inserire una data di fine</span>}
                </Col>
                <Col>
                  <Button type="submit" variant="primary" className="waste_btn" style={{'float':'left','marginTop':'45px'}}>Esporta</Button>
                </Col>
              </Row>            
            </form>
          </Col>
        </Row>
        {/* <Row className="action_bar bottom">
          <Col>
            <Button onClick={() => navigate('/users', { state: { page: (state && state.page ? state.page : 1), query: (state && state.query ? state.query : null) } })}><DynamicIcon iconName='IoIosArrowBack' /> Torna agli utenti</Button>
          </Col>
        </Row> */}
      </Container>
    </>
  );
  
}

export default ExportInvoiceable;