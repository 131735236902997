import React, { useContext, useEffect, useState } from 'react';
// AUTH
import { AuthContext } from '../contexts/AuthContext';
// REACT ROUTER
import { useNavigate, useLocation } from 'react-router-dom';
// AXIOS
import axios from 'axios';
// REACT HOOK FORM
import { useForm } from "react-hook-form";
// BOOTSTRAP
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
// REACT SPINNER
import { Watch } from 'react-loader-spinner';
// REACT SWEETALERT
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
// DYNAMIC ICON
import DynamicIcon from '../partials/DynamicIcon';

function CustomersAdd() {
  // AUTH, STATE, NAVIGATION
  const { authobj } = useContext(AuthContext);
  const { state } = useLocation();
  let navigate = useNavigate();
  // FORM
  const { register, handleSubmit, reset, getValues, setValue, formState: { errors } } = useForm();
  // SWEETALERT
  const MySwal = withReactContent(Swal);
  // DATA
  const [customer, setCustomer] = useState(null);
  const [roles, setRoles] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [customerType, setCustomerType] = useState(null);
  const [evaluationTypes, setEvaluationTypes] = useState([]);
  // SPINNER
  const [wait, setWait] = useState(false);
  const [waitPercent, setWaitPercent] = useState(null);

  useEffect(() => {
    setWait(true);
    // Se sto modificando un utente esistente, carico le informazioni relative
    // Carico le province
    axios.post(window.SERVER_URL+'provinces/list', { }, { headers: {
      'Access-Control-Allow-Origin': '*',
      'Authorization': 'Bearer '+authobj.token
    }}).then(res => {
      setProvinces(res.data.provinces);

      // Carico le tipologie di pratiche
      axios.post(window.SERVER_URL+'evaluation_types/list', { }, { headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer '+authobj.token
      }}).then(res => {
        setEvaluationTypes(res.data.evaluation_types);

        if (state && state.customer_id) {
          axios.post(window.SERVER_URL+'customers/get', { id: state.customer_id }, { headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization': 'Bearer '+authobj.token
          }}).then(res => {
            console.log(res.data);
            setCustomer(res.data);

            console.log(getValues());

            setCustomerType(res.data.user.profile.tipo);    

            // Setto i campi della form
            let fields = {};
            for (const [key, val] of Object.entries(res.data.user))
              if (typeof val !== 'object')
                if (getValues().hasOwnProperty(key))
                  fields[key] = val;
            for (const [key, val] of Object.entries(res.data.user.profile))
              if (typeof val !== 'object')
                if (getValues().hasOwnProperty(key))
                  fields[key] = val;
            fields['password'] = '**********';
            console.log(fields);
            reset(fields);

            if (res.data.user && res.data.user.prices) {
              res.data.user.prices.forEach((price) => {
                setValue("fp_prezzi["+price.evaluation_type_id+"][costo_cliente]", price.prezzo_cliente);
                setValue("fp_prezzi["+price.evaluation_type_id+"][costo_tecnico]", price.prezzo_tecnico);
                setValue("gp_prezzi["+price.evaluation_type_id+"][costo_cliente]", price.prezzo_cliente);
                setValue("gp_prezzi["+price.evaluation_type_id+"][costo_tecnico]", price.prezzo_tecnico);
              });
            }

            setWait(false);
          });
        } else {
          setWait(false);
        }
      });
    });
  }, [reset]);
  
  // Submit della form
  const onSubmit = (form_data) => {
    console.log(form_data);   
    form_data.id = (state && state.customer_id ? state.customer_id : null);
    axios.post(window.SERVER_URL+'customers/save', form_data, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer '+authobj.token,
        // 'Content-Type': 'multipart/form-data'
      }
    }).then(res => {
      if (res.data.success) {
        Swal.fire({
          icon: 'success',
          title: 'Cliente salvato',
          text: 'Cliente salvato correttamente'
        }).then((result) => {
          if (result.isConfirmed) {
            navigate('/customers');
          }
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Errore',
          text: "Non è stato possibile salvare il cliente"
        });
      }
    }).catch(function(error) {
      console.log(error);
      Swal.fire({
        icon: 'error',
        title: 'Errore',
        text: "Non è stato possibile salvare il cliente"
      });
    });
  }

  return (
    <>
      { wait ? 
        <div id="wait">
          { waitPercent !== null ?
            <div className="percent">{waitPercent}%</div>
          : '' }
          <Watch height="80" width="80" radius="48" color="#44B2EB" ariaLabel="watch-loading" wrapperStyle={{}} wrapperClassName="" visible={true} />
        </div>
      : '' }
      <Container className="content">
        <Row className="page_title">
          <Col>
            <h1>Aggiungi o modifica cliente</h1>
          </Col>
        </Row>
        <Row>
          <Col className="custom_form_wrapper">
            <form className="custom_form" onSubmit={handleSubmit(onSubmit)}>
              <fieldset style={{'background':'#ddd','padding':'5px 20px 20px'}}>
                <label className="fieldset_label"><DynamicIcon iconName="FaUser" size="20" spaced /> TIPO CLIENTE</label>
                <Row>
                  <Col>
                    <label>Tipo cliente *</label>
                    <select {...register("tipo", { required: true })} value={ customerType } onChange={(e) => setCustomerType(e.target.value) }>
                      <option value="" key="99999999">- Seleziona il tipo cliente -</option>
                      <option value="F" key="F">Persona fisica</option>
                      <option value="G" key="G">Soggetto giuridico</option>
                    </select>
                    {errors.province_id && <span>Devi selezionare una provincia</span>}
                  </Col>
                </Row>
              </fieldset>

              {/* CLIENTE - PERSONA FISICA */}
              <div className={customerType == 'F' ? 'fieldset_wrapper visible' : 'fieldset_wrapper '}>
                <fieldset style={{'background':'#f8f8f8','padding':'5px 20px 20px','marginTop':'20px'}}>
                  <label className="fieldset_label"><DynamicIcon iconName="FaUser" size="20" spaced /> INFORMAZIONI ANAGRAFICHE</label>
                  <Row>
                    <Col>
                      <label>Nome</label>
                      <input key={"nome"} type="text" placeholder="Nome" {...register("nome")} />
                    </Col>
                    <Col>
                      <label>Cognome</label>
                      <input key={"cognome"} type="text" placeholder="Cognome" {...register("cognome")} />
                    </Col>
                    <Col>
                      <label>Codice fiscale</label>
                      <input key={"f_codice_fiscale"} type="text" placeholder="Codice fiscale" {...register("f_codice_fiscale")} />
                    </Col>
                  </Row>
                </fieldset>
                <fieldset style={{'background':'#eee','padding':'5px 20px 20px','marginTop':'20px'}}>
                  <label className="fieldset_label"><DynamicIcon iconName="FaMapMarkerAlt" size="20" spaced /> RESIDENZA</label>
                  <Row>  
                    <Col>
                      <label>Via</label>
                      <input key={"f_indirizzo"} type="text" placeholder="Indirizzo" {...register("f_indirizzo")} />
                    </Col>
                    <Col>
                      <label>Num. civico</label>
                      <input key={"f_civico"} type="text" placeholder="Indirizzo" {...register("f_civico")} />
                    </Col>
                    <Col>
                      <label>CAP</label>
                      <input key={"f_cap"} type="text" placeholder="CAP" {...register("f_cap")} />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <label>Comune</label>
                      <input key={"f_comune"} type="text" placeholder="Comune" {...register("f_comune")} />
                    </Col>
                    <Col>
                      <label>Provincia</label>
                      <select key={"f_province_id"} {...register("f_province_id")}>
                        <option value="" key="99999999">- Seleziona la provincia -</option>
                        { provinces ? provinces.map((prov) => 
                          <option value={ prov.id } key={ prov.id }>{ prov.nome }</option>
                        ) : '' }
                      </select>
                    </Col>
                    <Col>
                      <label>Nazione</label>
                      <input key={"f_stato"} type="text" placeholder="Nazione" {...register("f_stato")} />
                    </Col>
                  </Row>
                </fieldset>
                <fieldset style={{'background':'#f8f8f8','padding':'5px 20px 20px','marginTop':'20px'}}>
                  <label className="fieldset_label"><DynamicIcon iconName="BsTelephoneFill" size="20" spaced /> INFORMAZIONI DI CONTATTO</label>
                  <Row>
                    <Col>
                      <label>Telefono</label>
                      <input key={"f_telefono"} type="text" placeholder="Telefono" {...register("f_telefono")} />
                    </Col>
                    <Col>
                      <label>Cellulare</label>
                      <input key={"f_cellulare"} type="text" placeholder="Cellulare" {...register("f_cellulare")} />
                    </Col>
                    <Col>
                      <label>E-mail</label>
                      <input key={"f_email"} type="email" placeholder="E-mail" {...register("f_email")} />
                    </Col>
                  </Row>
                </fieldset>
                <fieldset style={{'background':'#f8f8f8','padding':'5px 20px 20px','marginTop':'20px'}}>
                  <label className="fieldset_label"><DynamicIcon iconName="FaClipboardList" size="20" spaced /> LISTINO PREZZI</label>
                  <Row>
                    <Col>
                        <Table responsive style={{'marginTop':'20px'}}>
                          <thead>
                            <tr>
                              <th>Tipo perizia</th>
                              <th>Costo cliente</th>
                              <th>Costo tecnico</th>
                            </tr>
                          </thead>
                          <tbody>
                            { evaluationTypes && evaluationTypes.length ? evaluationTypes.map((type, i) =>
                              <tr>
                                <td>{ type.descrizione }</td>
                                <td>
                                  <div className='field_listino_wrapper'>
                                    <input className='field_listino' key={"fp_prezzi["+type.id+"][costo_cliente]"} type="number" step="0.10" placeholder="" {...register("fp_prezzi["+type.id+"][costo_cliente]")} />
                                  </div>
                                </td>
                                <td>
                                  <div className='field_listino_wrapper'>
                                    <input className='field_listino' key={"fp_prezzi["+type.id+"][costo_tecnico]"} type="number" step="0.10" placeholder="" {...register("fp_prezzi["+type.id+"][costo_tecnico]")} />
                                  </div>
                                </td>
                              </tr>
                            ) : '' }
                          </tbody>
                        </Table>
                    </Col>
                  </Row>
                </fieldset>
                <fieldset style={{'background':'#f8f8f8','padding':'5px 20px 20px','marginTop':'20px'}}>
                  <label className="fieldset_label"><DynamicIcon iconName="GrTextAlignLeft" size="20" spaced /> NOTE</label>
                  <Row>
                    <Col>
                      <textarea key={"f_note"} placeholder="Note" {...register("f_note")}></textarea>
                    </Col>
                  </Row>
                </fieldset>
              </div>

              {/* CLIENTE - SOGGETTO GIURIDICO */}
              <div className={customerType == 'G' ? 'fieldset_wrapper visible' : 'fieldset_wrapper '}>
                <fieldset style={{'background':'#f8f8f8','padding':'5px 20px 20px','marginTop':'20px'}}>
                  <label className="fieldset_label"><DynamicIcon iconName="FaUser" size="20" spaced /> INFORMAZIONI ANAGRAFICHE</label>
                  <Row>
                    <Col>
                      <label>Ragione sociale</label>
                      <input key={"g_ragione_sociale"} type="text" placeholder="Ragione sociale" {...register("g_ragione_sociale")} />
                    </Col>
                    <Col>
                      <label>Partita IVA</label>
                      <input key={"g_partita_iva"} type="text" placeholder="Partita IVA" {...register("g_partita_iva")} />
                    </Col>
                    <Col>
                      <label>Codice fiscale</label>
                      <input key={"g_codice_fiscale"} type="text" placeholder="Codice fiscale" {...register("g_codice_fiscale")} />
                    </Col>
                  </Row>
                </fieldset>
                <fieldset style={{'background':'#f8f8f8','padding':'5px 20px 20px','marginTop':'20px'}}>
                  <label className="fieldset_label"><DynamicIcon iconName="FaMapMarkerAlt" size="20" spaced /> SEDE LEGALE</label>
                  <Row>  
                    <Col>
                      <label>Indirizzo</label>
                      <input key={"g_indirizzo"} type="text" placeholder="Indirizzo" {...register("g_indirizzo")} />
                    </Col>
                    <Col>
                      <label>Num. civico</label>
                      <input key={"g_civico"} type="text" placeholder="Civico" {...register("g_civico")} />
                    </Col>
                    <Col>
                      <label>CAP</label>
                      <input key={"g_cap"} type="text" placeholder="CAP" {...register("g_cap")} />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <label>Comune</label>
                      <input key={"g_comune"} type="text" placeholder="Comune" {...register("g_comune")} />
                    </Col>
                    <Col>
                      <label>Provincia</label>
                      <select key={"g_province_id"} {...register("g_province_id")}>
                        <option value="" key="99999999">- Seleziona la provincia -</option>
                        { provinces.map((prov) => 
                          <option value={ prov.id } key={ prov.id }>{ prov.nome }</option>
                        )}
                      </select>
                    </Col>
                    <Col>
                      <label>Nazione</label>
                      <input key={"g_stato"} type="text" placeholder="Nazione" {...register("g_stato")} />
                    </Col>
                  </Row>
                </fieldset>
                <fieldset style={{'background':'#f8f8f8','padding':'5px 20px 20px','marginTop':'20px'}}>
                  <label className="fieldset_label"><DynamicIcon iconName="BsTelephoneFill" size="20" spaced /> INFORMAZIONI DI CONTATTO</label>
                  <Row>
                    <Col>
                      <label>Nome referente</label>
                      <input key={"g_referente_nome"} type="text" placeholder="Nome referente" {...register("g_referente_nome")} />
                    </Col>
                    <Col>
                      <label>Cognome referente</label>
                      <input key={"g_referente_cognome"} type="text" placeholder="Cognome referente" {...register("g_referente_cognome")} />
                    </Col>
                    <Col>
                      <label>E-mail referente</label>
                      <input key={"g_referente_email"} type="email" placeholder="E-mail referente" {...register("g_referente_email")} />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <label>Telefono</label>
                      <input key={"g_telefono"} type="text" placeholder="Telefono" {...register("g_telefono")} />
                    </Col>
                    <Col>
                      <label>Cellulare</label>
                      <input key={"g_cellulare"} type="text" placeholder="Cellulare" {...register("g_cellulare")} />
                    </Col>
                    <Col>
                      <label>E-mail azienda</label>
                      <input key={"g_email"} type="email" placeholder="E-mail azienda" {...register("g_email")} />
                    </Col>
                  </Row>
                </fieldset>
                <fieldset style={{'background':'#f8f8f8','padding':'5px 20px 20px','marginTop':'20px'}}>
                  <label className="fieldset_label"><DynamicIcon iconName="FaFileInvoiceDollar" size="20" spaced /> DATI FATTURAZIONE</label>
                  <Row>
                    <Col>
                      <label>PEC</label>
                      <input key={"g_pec"} type="text" placeholder="PEC" {...register("g_pec")} />
                    </Col>
                    <Col>
                      <label>Codice univoco</label>
                      <input key={"g_codice_univoco"} type="text" placeholder="Codice univoco" {...register("g_codice_univoco")} />
                    </Col>
                  </Row>
                </fieldset>
                <fieldset style={{'background':'#f8f8f8','padding':'5px 20px 20px','marginTop':'20px'}}>
                  <label className="fieldset_label"><DynamicIcon iconName="FaClipboardList" size="20" spaced /> LISTINO PREZZI</label>
                  <Row>
                    <Col>
                        <Table responsive style={{'marginTop':'20px'}}>
                          <thead>
                            <tr>
                              <th>Tipo perizia</th>
                              <th>Costo cliente</th>
                              <th>Costo tecnico</th>
                            </tr>
                          </thead>
                          <tbody>
                            { evaluationTypes && evaluationTypes.length ? evaluationTypes.map((type, i) =>
                              <tr>
                                <td>{ type.descrizione }</td>
                                <td>
                                  <div className='field_listino_wrapper'>
                                    <input className='field_listino' key={"gp_prezzi["+type.id+"][costo_cliente]"} type="number" step="0.10" placeholder="" {...register("gp_prezzi["+type.id+"][costo_cliente]")} />
                                  </div>
                                </td>
                                <td>
                                  <div className='field_listino_wrapper'>
                                    <input className='field_listino' key={"gp_prezzi["+type.id+"][costo_tecnico]"} type="number" step="0.10" placeholder="" {...register("gp_prezzi["+type.id+"][costo_tecnico]")} />
                                  </div>
                                </td>
                              </tr>
                            ) : '' }
                          </tbody>
                        </Table>
                    </Col>
                  </Row>
                </fieldset>
                <fieldset style={{'background':'#f8f8f8','padding':'5px 20px 20px','marginTop':'20px'}}>
                  <label className="fieldset_label"><DynamicIcon iconName="GrTextAlignLeft" size="20" spaced /> NOTE</label>
                  <Row>
                    <Col>
                      <textarea key={"g_note"} placeholder="Note" {...register("g_note")}></textarea>
                    </Col>
                  </Row>
                </fieldset>
              </div>

              <fieldset style={{'background':'#f8f8f8','padding':'5px 20px 20px','marginTop':'20px'}}>
                <label className="fieldset_label"><DynamicIcon iconName="FaKey" size="20" spaced /> ACCESSO E PERMESSI</label>
                <Row>
                  <Col>
                    <label>E-mail *</label>
                    <input key={"email"} type="email" placeholder="E-mail" {...register("email", { required: true })} />
                    {errors.email && <span>Devi inserire un indirizzo e-mail</span>}
                  </Col>
                  <Col>
                    <label>Password *</label>
                    <input key={"password"} type="password" placeholder="Password" {...register("password", { required: true })} />
                    {errors.password && <span>Devi inserire una password</span>}
                  </Col>
                  <Col>
                    <label>Creazione sub-utenti *</label>
                    <select key={"sub_utenti"} {...register("sub_utenti", { required: true })}>
                      <option value="0" key="0">No</option>
                      <option value="1" key="1">Sì</option>
                    </select>
                  </Col>
                </Row>
              </fieldset>

              <Button type="submit" variant="primary" className="waste_btn">Salva</Button>
             
            </form>
          </Col>
        </Row>
        <Row className="action_bar bottom">
          <Col>
            <Button onClick={() => navigate('/customers', { state: { page: (state && state.page ? state.page : 1), query: (state && state.query ? state.query : null) } })}><DynamicIcon iconName='IoIosArrowBack' /> Torna ai clienti</Button>
          </Col>
        </Row>
      </Container>
    </>
  );
  
}

export default CustomersAdd;