import React, { useState, useContext, useEffect, useRef } from 'react';
// AUTH
import { AuthContext } from '../contexts/AuthContext';
// REACT ROUTER
import { useNavigate, useLocation } from 'react-router-dom';
// AXIOS
import axios from 'axios';
// TIMEPICKER
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
// REACT SPINNER
import { Watch } from 'react-loader-spinner';
// REACT SWEETALERT
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

export function useHelpers() {
    // AUTH, STATE, NAVIGATION
    const { authobj, setAuthobj, globalRefresh, setGlobalRefresh } = useContext(AuthContext);
    let navigate = useNavigate();
    // REFS
    const timeRef = useRef(null);
    // DATA
    const [appointmentTime, setAppointmentTime] = useState(null);
    // SWEETALERT
    const MySwal = withReactContent(Swal);
    // SPINNER
    const [wait, setWait] = useState(false);

    useEffect(() => {
    }, []);

    const formatTime = (date) => {
      const hours = String(date.getHours()).padStart(2, '0'); // Get hours and pad with zero if needed
      const minutes = String(date.getMinutes()).padStart(2, '0'); // Get minutes and pad with zero
      const seconds = String(date.getSeconds()).padStart(2, '0'); // Get seconds and pad with zero 
      return `${hours}:${minutes}`; // Return formatted string
    }

    function roundToNearest15Minutes(date) {
      const roundedDate = new Date(date);
      const minutes = roundedDate.getMinutes();
      const remainder = minutes % 15;
      if (remainder >= 8) {
        roundedDate.setMinutes(minutes + (15 - remainder));
      } else {
        roundedDate.setMinutes(minutes - remainder);
      }
      roundedDate.setSeconds(0);
      roundedDate.setMilliseconds(0);
      return roundedDate;
    }

    function handleTimeChange(val) {
      timeRef.current = val;
      console.log(val);
      const roundedTime = roundToNearest15Minutes(val);
      setAppointmentTime(roundedTime);
    };

    const updateUserState = (showPopup) => {
        axios.post(window.SERVER_URL+'users/sla_late', {}, { headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization': 'Bearer '+authobj.token
        }}).then(res => {
            console.log(res.data);
            let tmp_authobj = authobj;
            tmp_authobj.is_late = res.data.is_late;
            tmp_authobj.count_late = res.data.count_late;
            setAuthobj(tmp_authobj);
            if (showPopup)
              handleUserState();
        });
    }

    const handleUserState = () => {
      if (authobj.user.role.slug == 'tec') {
        if (authobj.user.stato_note) {
            Swal.fire({
              icon: 'warning',
              title: "Il tuo utente è sospeso",
              html: "<b>"+authobj.user.stato_note+"</b>",
              showDenyButton: false,
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              // cancelButtonColor: '#d33',
            }).then((result) => {
              navigate('/technicians/add', { state: { technician_id: authobj.user.id } });
            });
        }
    
        if (authobj.is_late > 0) {
            const { value: formValues } = Swal.fire({
                html: `<img src="${process.env.PUBLIC_URL}/gfx/Griglia di ripetizione 4.png"><br>
                        <b>ATTENZIONE!</b><br>
                        <p>Ci sono <b>${authobj.count_late} pratiche</b> a te assegnate che risultano avere delle anomalie.<br>
                        Risolvi la situazione per poter accedere alla dashboard.</p>
                        `,
                width: '800px',
                showDenyButton: false,
                showCancelButton: false,
                showConfirmButton: true,
                confirmButtonText: "Vai alle pratiche con anomalie",
                confirmButtonColor: "#B13905",
                denyButtonColor: "#12275d",
                cancelButtonColor: "#12275d"
            }).then((result) => {
                navigate('/dashboard/late');
            });
        }
      }
    }

    const geocode_point = (address) => {
        return axios.get(window.GMAPS_GEOCODE_URL+address+'&key='+window.GMAPS_APIKEY);
    }

    const handleAppointment = (evaluation_id) => {
        axios.post(window.SERVER_URL+'evaluations/get', { id: evaluation_id }, { headers: {
          'Access-Control-Allow-Origin': '*',
          'Authorization': 'Bearer '+authobj.token
        }}).then(res => {
          console.log(res.data);
    
          let apps = res.data.evaluation.appointments;
    
          const { value: formValues } = MySwal.fire({
            html: (
              <div>
                <img src={process.env.PUBLIC_URL+'/gfx/Griglia di ripetizione 5.png'} />
                <br />
                <h2>Fissa o gestisci l'appuntamento</h2>
                <br />
                <table id="tab_app">
                  { apps && apps.length ? apps.map((app, index) => 
                    <tr className={index == (apps.length - 1) ? 'last' : ''}>
                      <td style={{'textAlign':'left','width':'120px'}}>{app.data_appuntamento}</td>
                      <td style={{'textAlign':'left'}}>{app.ora_appuntamento.substr(0, 5)}</td>
                      <td style={{'textAlign':'left'}} className="note">{app.note ? app.note : ''}</td>
                      <td style={{'textAlign':'right'}}>{app.deleted_at ? <b style={{'color':'#B13905'}}>Non eseguito</b> : (app.svolto == 1 ? <b style={{'color':'#12275D'}}>Eseguito</b> : <b style={{'color':'green'}}>Da fare</b>)}</td>
                    </tr>
                  ) : '' }
                </table>
                { !res.data.evaluation.appointment || res.data.evaluation.appointment.svolto == 0 ? 
                  <table>
                    <tr>
                      <td className="left">
                        <p>Nome<br /><b>{res.data.evaluation.sopralluogo_referente}</b></p>
                        <p>Telefono<br /><b>{res.data.evaluation.sopralluogo_telefono_1}</b></p>
                        <p>Cellulare<br /><b>{res.data.evaluation.sopralluogo_cellulare}</b></p>
                        <p>E-mail<br /><b>{res.data.evaluation.sopralluogo_email}</b></p>
                      </td>
                      <td className="left">
                        <table style={{'margin':'0','width':'100% !important'}}>
                          <tr style={{'border':'0 !important'}}>
                            <td style={{'paddingLeft':'0 !important'}}>
                              Data<br />
                              <input id="appointment_date" name="appointment_date" type="date" min={new Date().toISOString().slice(0, 10)} /><br />
                            </td>
                            <td style={{'paddingRight':'0 !important'}}>
                              Ora<br />
                              <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <TimePicker 
                                  views={['hours', 'minutes']} 
                                  ampm={false} 
                                  ampmInClock={false} 
                                  format="HH:mm" 
                                  minutesStep={15} 
                                  minTime={new Date().setHours(7, 0)}
                                  maxTime={new Date().setHours(20, 0)}
                                  value={appointmentTime} 
                                  onChange={(val) => handleTimeChange(val)}
                                  slotProps={{
                                    textField: {
                                      inputProps: {
                                        readOnly: true,
                                      },
                                    },
                                  }}
                                />
                              </LocalizationProvider>
                            </td>
                          </tr>
                        </table>
                        Note<br />
                        <textarea id="appointment_note" name="appointment_note"></textarea>
                      </td>
                    </tr>
                  </table>
                : ''}
              </div>
            ),
            width: '800px',
            preConfirm: () => {
              return [
                document.getElementById("appointment_date").value,
                // document.getElementById("appointment_hour").value,
                // appointmentTime,
                document.getElementById("appointment_note").value
              ];
            },
            showDenyButton: (res.data.evaluation.appointment && res.data.evaluation.appointment.svolto == 0 ? true : false),
            showCancelButton: false,
            showConfirmButton: (res.data.evaluation.appointment && res.data.evaluation.appointment.svolto == 1 ? false : true),
            confirmButtonText: "Fissa l'appuntamento",
            denyButtonText: "Segna come svolto",
            confirmButtonColor: "#12275d",
            denyButtonColor: "#12275d",
            cancelButtonColor: "#12275d"
          }).then((result) => {
            // console.log(result);
            // console.log(JSON.stringify(formValues));
            if (result.isConfirmed) {
              console.log("DATA APPUNTAMENTO: "+result.value[0]);
              console.log("ORA APPUNTAMENTO: "+timeRef.current);
              // console.log("ORA APPUNTAMENTO: "+formatTime(appointmentTime));
              console.log("NOTE APPUNTAMENTO: "+result.value[1]);

              // CONTROLLO DATA APPUNTAMENTO
              const appointmentDate = new Date(result.value[0]);
              const hours = timeRef.current.getHours();
              const minutes = timeRef.current.getMinutes();
              appointmentDate.setHours(hours);
              appointmentDate.setMinutes(minutes);
              appointmentDate.setSeconds(0);

              if (appointmentDate > new Date()) {
                // salva appuntamento
                axios.post(window.SERVER_URL+'appointments/save', { evaluation_id, 'data_appuntamento': result.value[0], 'ora_appuntamento': formatTime(timeRef.current), 'note': result.value[1] }, { headers: {
                  'Access-Control-Allow-Origin': '*',
                  'Authorization': 'Bearer '+authobj.token
                }}).then(res => {
                  console.log(res.data);
                  if (res.data.success) {
                    Swal.fire({
                      'icon': 'success',
                      'title': 'Appuntamento salvato',
                      'text': 'Nuovo appuntamento registrato correttamente'
                    });
                    setGlobalRefresh();
                  } else {
                    Swal.fire({
                      'icon': 'error',
                      'title': 'Errore salvataggio',
                      'text': "Errore durante il salvataggio dell'appuntamento"
                    });
                  }
                });
              } else {
                Swal.fire({
                  'icon': 'error',
                  'title': 'Data e/o ora errati',
                  'text': "Non puoi impostare l'appuntamento in un momento passato"
                });
              }
            } else if (result.isDenied) {
              console.log(res.data.evaluation.appointment);

              let appointment = res.data.evaluation.appointment;
              const [day, month, year] = appointment.data_appuntamento.split('/').map(Number);
              const [hours, minutes, seconds] = appointment.ora_appuntamento.split(':').map(Number);
              const appointmentDateTime = new Date(year, month - 1, day, hours, minutes, seconds); // month is 0-indexed

              if (appointmentDateTime < new Date()) {
                // fissa come svolto
                axios.post(window.SERVER_URL+'appointments/set_done', { evaluation_id }, { headers: {
                  'Access-Control-Allow-Origin': '*',
                  'Authorization': 'Bearer '+authobj.token
                }}).then(res => {
                  console.log(res.data);
                  if (res.data.success) {
                    Swal.fire({
                      'icon': 'success',
                      'title': 'Appuntamento svolto',
                      'text': "L'appuntamento è stato impostato come svolto"
                    }).then((result) => {
                      // getPage(
                      //   state && state.page ? state.page : page,
                      //   state && state.query ? state.query : query
                      // );
                      setGlobalRefresh();
                    });
      
                  } else {
                    Swal.fire({
                      'icon': 'error',
                      'title': 'Errore salvataggio',
                      'text': "Impossibile impostare l'appuntamento come svolto"
                    });
                  }
                });
              } else {
                Swal.fire({
                  'icon': 'error',
                  'title': "Impossibile svolgere l'appuntamento",
                  'text': "Non puoi impostare come svolto un appuntamento che non è trascorso"
                });
              }
            }
          });
        });
    }

    const handleRework = (evaluation_id) => {
        axios.post(window.SERVER_URL+'evaluations/get', { id: evaluation_id }, { headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization': 'Bearer '+authobj.token
        }}).then(res => {
            console.log(res.data);
            let tmp_eval = res.data.evaluation;
        
            const { value: formValues } = Swal.fire({
                html: `<img src="${process.env.PUBLIC_URL}/gfx/Griglia di ripetizione 4.png"><br>
                        <h2>GESTIONE SOSPENSIONE</h2><br>
                        <table><tr>
                            <td class="left" style="width: 50%; line-height: 24px; vertical-align: top;">
                                <b>Owner</b><br>
                                ${tmp_eval.in_carico ? tmp_eval.in_carico.nome : ''} ${tmp_eval.in_carico ? tmp_eval.in_carico.cognome : ''}
                                <br><br>
                                <b>Motivo</b><br>
                                ${tmp_eval.notebooks[0].popup_motif.descrizione}
                                <br><br>
                                <b>Note aggiuntive</b><br>
                                ${tmp_eval.notebooks[0].note}
                            </td>
                            <td class="left" style="width: 50%; line-height: 24px; vertical-align: top;">
                                <b>Data e ora</b><br>
                                ${tmp_eval.notebooks[0].created_at.substr(0, 10).split("-").reverse().join("/")} ${tmp_eval.notebooks[0].created_at.substr(11, 5)}
                                <br><br>
                                <b>Note per il rework</b><br>
                                <textarea id="rework_note" name="suspension_note" placeholder="Inserisci note aggiuntive"></textarea>
                            </td>
                        </tr></table>
                        <br><br>
                        `,
                width: '800px',
                preConfirm: () => {
                    return [
                        document.getElementById("rework_note").value
                    ];
                },
                showDenyButton: false,
                showCancelButton: true,
                showConfirmButton: true,
                confirmButtonText: "Rilavora pratica",
                cancelButtonText: "Ci ho ripensato",
                confirmButtonColor: "#12275d",
                denyButtonColor: "#12275d",
                cancelButtonColor: "#12275d"
            }).then((result) => {
                if (result.isConfirmed) {
                    axios.post(window.SERVER_URL+'evaluations/set_unsuspended', { id: evaluation_id, popup_motif_id: 13, note: result.value[0] }, { headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Authorization': 'Bearer '+authobj.token
                    }}).then(res => {
                    console.log(res.data);
                    if (res.data.success) {
                        Swal.fire({
                            'icon': 'success',
                            'title': 'Perizia rimessa in lavorazione',
                            'text': 'La perizia è stata rimessa in lavorazione correttamente'
                        });
                        setGlobalRefresh();
                        navigate('/dashboard');
                    } else {
                        Swal.fire({
                            'icon': 'danger',
                            'title': 'Errore',
                            'text': "Impossibile rimettere la perizia in lavorazione"
                        });
                    }
                    });
                }
            });
        });
    }

    const handleSuspension = (evaluation_id) => {
      axios.post(window.SERVER_URL+'popup_motifs/list', { sezione: 'evaluation_suspended', evaluation_id }, { headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer '+authobj.token
      }}).then(res => {
        console.log(res.data);
          
        let popup_select = '<select id="sospensione_motivo" name="sospensione_motivo">';
        popup_select += '<option value="">- Seleziona la motivazione -</option>';
        res.data.motifs.forEach((opt) => {
          popup_select += '<option value="'+opt.id+'">'+opt.descrizione+'</option>';
        });
        popup_select += '</select>';

        const { value: formValues } = Swal.fire({
          html: `<img src="${process.env.PUBLIC_URL}/gfx/Griglia di ripetizione 4.png"><br>
                  <b>ATTENZIONE!</b><br>
                  <p>Devi indicare il motivo per cui vuoi sospendere la perizia; descrivi bene la situazione.</p>
                  ${popup_select}                 
                  <textarea id="suspension_note" name="suspension_note" placeholder="Motivazione sospensione"></textarea>
                  <br><br>
                  `,
          width: '800px',
          preConfirm: () => {
            return [
              document.getElementById("sospensione_motivo").value,
              document.getElementById("suspension_note").value
            ];
          },
          showDenyButton: false,
          showCancelButton: true,
          showConfirmButton: true,
          confirmButtonText: "Sospendi la perizia",
          cancelButtonText: "Ci ho ripensato",
          confirmButtonColor: "#12275d",
          denyButtonColor: "#12275d",
          cancelButtonColor: "#12275d"
        }).then((result) => {
          if (result.isConfirmed) {
            // sospendi la perizia
            axios.post(window.SERVER_URL+'evaluations/set_suspended', { id: evaluation_id, popup_motif_id: result.value[0], note: result.value[1] }, { headers: {
              'Access-Control-Allow-Origin': '*',
              'Authorization': 'Bearer '+authobj.token
            }}).then(res => {
            console.log(res.data);
            if (res.data.success) {
              Swal.fire({
                'icon': 'success',
                'title': 'Perizia sospesa',
                'text': 'La perizia è stata sospesa correttamente'
              });
              setGlobalRefresh();
              navigate('/dashboard');
            } else {
              Swal.fire({
                'icon': 'danger',
                'title': 'Errore',
                'text': "Impossibile impostare la perizia come sospesa"
              });
            }
            });
          }
        });
      });
    }

    const handleAddAsset = (evaluation_id) => {
      // Carico le categorie catastali
      axios.post(window.SERVER_URL+'registry_categories/list', { }, { headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer '+authobj.token
      }}).then(rescat => {
        let registryCategories = rescat.data.registry_categories;

        // Carico le tipologie di particelle
        axios.post(window.SERVER_URL+'parcel_types/list', { }, { headers: {
          'Access-Control-Allow-Origin': '*',
          'Authorization': 'Bearer '+authobj.token
        }}).then(restype => {
          let parcelTypes = restype.data.parcel_types;

          // Carico i comuni
          axios.post(window.SERVER_URL+'towns/list', { }, { headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization': 'Bearer '+authobj.token
          }}).then(restown => {
            let towns = restown.data.towns;

            let asset_cat_select = '<select id="registry_category_id" name="registry_category_id">';
            asset_cat_select += '<option value="">- Categoria catastale -</option>';
            registryCategories.forEach((cat) => {
              asset_cat_select += '<option value="'+cat.id+'">'+cat.descrizione+'</option>';
            });
            asset_cat_select += '</select>';
        
            let asset_type_select = '<select id="parcel_type_id" name="parcel_type_id">';
            asset_type_select += '<option value="">- Tipo -</option>';
            parcelTypes.forEach((cat) => {
              asset_type_select += '<option value="'+cat.id+'">'+cat.descrizione+'</option>';
            });
            asset_type_select += '</select>';

            let town_select = '<select id="town_id" name="town_id">'
            town_select += '<option value="">- Seleziona il comune -</option>';
            towns.forEach((t) => {
              town_select += '<option value="'+t.id+'">'+t.nome+' ('+t.province.sigla+')</option>';
            });
            town_select += '</select>';
        
            Swal.fire({
              html: `<b>AGGIUNGI NUOVO BENE ALLA PERIZIA</b><br>
                      <p><b>Inserisci i dati del bene da aggiungere:</b></p>
                      <table>
                        <tr>
                          <td>${asset_type_select}</td>  
                          <td colspan="3">${town_select}</td>
                        </tr>
                        <tr>
                          <td><input type="text" maxLength="4" id="foglio" name="foglio" placeholder="Foglio"></td>
                          <td><input type="text" maxLength="5" id="mappale" name="mappale" placeholder="Particella"></td>
                          <td><input type="number" step="1" id="subalterno" name="subalterno" placeholder="Subalterno"></td>
                          <td>${asset_cat_select}</td>
                        </tr>
                      </table>
                      <br><br>`,
              showDenyButton: false,
              showCancelButton: false,
              confirmButtonText: "Salva",
              cancelButtonText: "Annulla",
              confirmButtonColor: "#12275d",
              cancelButtonColor: "#12275d",
              width: '800px',
              preConfirm: () => {
                return [
                  document.getElementById("town_id").value,
                  document.getElementById("registry_category_id").value,
                  document.getElementById("parcel_type_id").value,
                  document.getElementById("foglio").value,
                  document.getElementById("mappale").value,
                  document.getElementById("subalterno").value
                ];
              }
            }).then((result) => {
              if (result.isConfirmed) {
                setWait(true);
                axios.post(window.SERVER_URL+'assets/add', { 
                  evaluation_id: evaluation_id, 
                  town_id: result.value[0],
                  registry_category_id: result.value[1],
                  parcel_type_id: result.value[2],
                  foglio: result.value[3], 
                  mappale: result.value[4], 
                  subalterno: result.value[5] 
                }, { headers: {
                  'Access-Control-Allow-Origin': '*',
                  'Authorization': 'Bearer '+authobj.token
                }}).then(res => {
                  setGlobalRefresh(1);
                  setWait(false);
                });
              }
            });
          });
        });
      });
    }

    return { handleUserState, updateUserState, handleAppointment, handleSuspension, handleRework, geocode_point, handleAddAsset };
}