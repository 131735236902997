import React, { useState, useEffect, useContext } from 'react';
// AUTH
import { AuthContext } from '../contexts/AuthContext';
// REACT ROUTER
import { useNavigate } from 'react-router-dom';
// CUSTOM HOOKS
import { useHelpers } from '../hooks/useHelpers';
// AXIOS
import axios from 'axios';
// BOOTSTRAP
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
// REACT SWEETALERT
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
// DYNAMIC ICON
import DynamicIcon from '../partials/DynamicIcon';
import Evaluations from './evaluations';
// PARTIALS
import Calendar from '../partials/calendar';

function Dashboard() {
  // AUTH, STATE, NAVIGATION
  const { authobj, signout, globalRefresh, setGlobalRefresh } = useContext(AuthContext);
  let navigate = useNavigate();
  // CUSTOM HOOKS
  const { handleUserState, updateUserState } = useHelpers();
  // DATA
  const [counters, setCounters] = useState(null);

  const getIconByRule = (rolename) => {
    switch (rolename) {
      case 'backoffice':
        return 'logo_backoffice.png';
      break;
      case 'tec':
        return 'logo_user_tecnico.png';
      break;
      case 'qual-cont':
        return 'logo_user_qualita1.png';
      break;
      case 'qual-val':
        return 'logo_user_qualita2.png';
      break;
      case 'cliente':
        return 'logo_user_cliente.png';
      break;
      default:
        return 'logo_backoffice.png';
      break;
    }
  }

  useEffect(() => {
    updateUserState(true);
    axios.post(window.SERVER_URL+'evaluations/get_widgets', { }, { headers: {
      'Access-Control-Allow-Origin': '*',
      'Authorization': 'Bearer '+authobj.token
    }}).then(res => {
      console.log(res.data);
      setCounters(res.data);
    });
  }, [globalRefresh]);
  
  return (
    <Container className="content dashboard">
      <Row className="page_title">
        <Col>
          <img src={`${process.env.PUBLIC_URL}/gfx/${ getIconByRule(authobj.user.role.slug) }`} />
          <p className='greeting'>Ciao { authobj.role }<br />Il resoconto della tua attività</p>
          <br /><br />
          {/* <p className='role_label'>{ authobj.user.role.slug }</p>
          <p className='motivation'>È il giorno giusto per fare al meglio il tuo lavoro.</p> */}
        </Col>
        <Col>
          <div className={'count_wrapper '+authobj.user.role.slug}>
            { authobj.user.role.slug == 'superadmin' ?
              <>
                <div className='count_block'>
                  <span className='num'>{ counters ? counters.in_ingresso : '0' }</span>
                  <span className='title'>Pratiche<br /><b>in entrata</b></span>
                </div>
                <div className='count_block'>
                  <span className='num'>{ counters ? counters.assegnate : '0' }</span>
                  <span className='title'>Pratiche<br /><b>in lavorazione</b></span>
                </div>
                <div className='count_block'>
                  <span className='num'>{ counters ? counters.sospese : '0' }</span>
                  <span className='title'>Pratiche<br /><b>sospese</b></span>
                </div>
                <div className='count_block'>
                  <span className='num'>{ counters ? counters.completate : '0' }</span>
                  <span className='title'>Pratiche<br /><b>completate</b></span>
                </div>
              </>
            : authobj.user.role.slug == 'tec' ?
              <>
                <div className='count_block'>
                  <span className='num'>{ counters ? counters.assegnate : '0' }</span>
                  <span className='title'>Pratiche<br /><b>assegnate</b></span>
                </div>
                <div className='count_block'>
                  <span className='num'>{ counters ? counters.in_gestione : '0' }</span>
                  <span className='title'>Pratiche<br /><b>in gestione</b></span>
                </div>
                <div className='count_block'>
                  <span className='num'>{ counters ? counters.sospese : '0' }</span>
                  <span className='title'>Pratiche<br /><b>sospese</b></span>
                </div>
                <div className='count_block'>
                  <span className='num'>{ counters ? counters.completate : '0' }</span>
                  <span className='title'>Pratiche<br /><b>da fatturare</b></span>
                </div>
              </>
            : authobj.user.role.slug == 'qual-cont' ?
              <>
                <div className='count_block'>
                  <span className='num'>{ counters ? counters.da_prendere : '0' }</span>
                  <span className='title'>Pratiche da<br /><b>prendere in carico</b></span>
                </div>
                <div className='count_block'>
                  <span className='num'>{ counters ? counters.prese : '0' }</span>
                  <span className='title'>Pratiche<br /><b>prese in carico</b></span>
                </div>
                <div className='count_block'>
                  <span className='num'>{ counters ? counters.sospese : '0' }</span>
                  <span className='title'>Pratiche<br /><b>sospese</b></span>
                </div>
                <div className='count_block'>
                  <span className='num'>{ counters ? counters.completate : '0' }</span>
                  <span className='title'>Pratiche<br /><b>completate</b></span>
                </div>
              </>
            : authobj.user.role.slug == 'qual-val' ?
              <>
                <div className='count_block'>
                  <span className='num'>{ counters ? counters.da_prendere : '0' }</span>
                  <span className='title'>Pratiche da<br /><b>prendere in carico</b></span>
                </div>
                <div className='count_block'>
                  <span className='num'>{ counters ? counters.prese : '0' }</span>
                  <span className='title'>Pratiche<br /><b>prese in carico</b></span>
                </div>
                <div className='count_block'>
                  <span className='num'>{ counters ? counters.sospese : '0' }</span>
                  <span className='title'>Pratiche<br /><b>sospese</b></span>
                </div>
                <div className='count_block'>
                  <span className='num'>{ counters ? counters.completate : '0' }</span>
                  <span className='title'>Pratiche<br /><b>evase</b></span>
                </div>
              </>
            : '' }
          </div>
        </Col>
      </Row>
      { ['superadmin','back-a','back-b'].includes(authobj.user.role.slug) ?
        <Row>
          <Col>
            <h2>Overview pratiche</h2>
            <Evaluations isWidget />
          </Col>
        </Row>
      : authobj.user.role.slug == 'tec' ?
        <Row>
          <Col lg={9}>
            <h2 className='underline'>
              <img src={`${process.env.PUBLIC_URL}/gfx/Tracciato 262.png`} style={{'display':'inline-block','marginRight':'10px'}} />
              Listato totale delle tue pratiche ({ counters ? counters.totale : '0' })
            </h2>
            <Evaluations isWidget />
          </Col>
          <Col lg={3}>
            <h2 className='underline'>
              <img src={`${process.env.PUBLIC_URL}/gfx/Icon feather-calendar@2x.png`} style={{'display':'inline-block','marginRight':'10px'}} />
              Appuntamenti fissati
            </h2>
            <Calendar />
          </Col>
        </Row>
      : authobj.user.role.slug == 'qual-cont' ?
        <Row>
          <Col lg={12}>
            <h2 className='underline'>
              <img src={`${process.env.PUBLIC_URL}/gfx/Tracciato 262.png`} style={{'display':'inline-block','marginRight':'10px'}} />
              Elenco pratiche da controllare
            </h2>
            <Evaluations isWidget />
          </Col>
          {/* <Col lg={3}>
            <h2 className='underline'>Sidebar</h2>
            <div style={{'minHeight':'800px','background':'#eee','padding':'20px','textAlign':'center','fontWeight':'700','fontSize':'30px'}}>SIDEBAR</div>
          </Col> */}
        </Row>
      : authobj.user.role.slug == 'qual-val' ?
        <Row>
          <Col lg={12}>
            <h2 className='underline'>
              <img src={`${process.env.PUBLIC_URL}/gfx/Tracciato 262.png`} style={{'display':'inline-block','marginRight':'10px'}} />
              Elenco pratiche da validare
            </h2>
            <Evaluations isWidget />
          </Col>
          {/* <Col lg={3}>
            <h2 className='underline'>Sidebar</h2>
            <div style={{'minHeight':'800px','background':'#eee','padding':'20px','textAlign':'center','fontWeight':'700','fontSize':'30px'}}>SIDEBAR</div>
          </Col> */}
        </Row>
      : '' }
    </Container>
  );

  {/*
  <Button variant="success" style={{margin:'0 10px'}} onClick={() => { navigate('/admin/news/add'); }}><BiPlus /> lorem ipsum</Button>
  <Button variant="success" style={{margin:'0 10px'}} onClick={() => { navigate('/admin/inspections/add'); }}><BiPlus /> lorem ipsum</Button>
  */}
  
}

export default Dashboard;