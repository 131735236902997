import React, { useState, useEffect, useContext } from 'react';
// AUTH
import { AuthContext } from '../contexts/AuthContext';
// REACT ROUTER
import { useNavigate, useLocation } from 'react-router-dom';
// AXIOS
import axios from 'axios';
// BOOTSTRAP
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
// REACT SWEETALERT
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
// DYNAMIC ICON
import DynamicIcon from '../partials/DynamicIcon';

function Users() {
  // AUTH, STATE, NAVIGATION
  const { authobj } = useContext(AuthContext);
  const { state } = useLocation();
  let navigate = useNavigate();
  // SWEETALERT
  const MySwal = withReactContent(Swal);
  // DATA
  const [users, setUsers] = useState(null);
  const [page, setPage] = useState(1);
  const [numPages, setNumPages] = useState(1);
  const [count, setCount] = useState(null);
  const [query, setQuery] = useState(null);

  useEffect(() => {
    // document.getElementById('query').value = state && state.query ? state.query : query;
    getPage(
      state && state.page ? state.page : page,
      state && state.query ? state.query : query
    );
  }, []);

  const getPage = (i, query) => {
    let params = {
      page: i
    }
    if (query && query.length >= 3)
      params.search = query;
      
    axios.post(window.SERVER_URL+'users/list', { params }, { headers: {
      'Access-Control-Allow-Origin': '*',
      'Authorization': 'Bearer '+authobj.token
    }}).then(res => {
      console.log(res.data);
      setUsers(res.data.users);
      setPage(i);
      setNumPages(res.data.pages);
      setCount(res.data.count);
      setQuery(query);
    });
  }

  const handleInputChange = (typed) => {
    console.log(typed);
    getPage(1, typed);
  }

  const handleDelete = (user_id) => {
    Swal.fire({
      icon: 'warning',
      title: "Vuoi davvero cancellare l'utente?",
      html: "<b>ATTENTO!</b> L'operazione non è reversibile.",
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: 'Cancella',
      // denyButtonText: 'Annulla',
      cancelButtonText: 'Annulla',
      confirmButtonColor: "#12275d",
      denyButtonColor: "#12275d",
      cancelButtonColor: "#12275d"
    }).then((result) => {
      if (result.isConfirmed) {
        axios.post(window.SERVER_URL+'users/delete', { id: user_id }, { headers: {
          'Access-Control-Allow-Origin': '*',
          'Authorization': 'Bearer '+authobj.token
        }}).then(res => {
          if (res.data.success === true) {
            // se la cancellazione è andata a buon fine
            setUsers(users.filter(user => user.id !== user_id));
            getPage(1, query);
            Swal.fire({
              icon: 'success',
              title: 'Utente cancellato',
              text: 'Utente cancellato correttamente'
            });
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Errore',
              text: "Non è stato possibile cancellare l'utente"
            });
          }
        });
      }
    });
  }
  
  return (
    <>
      <Container className="content">
        <Row className="page_title">
          <Col>
            <h1>Utenti</h1>
          </Col>
        </Row>
        <Row className="action_bar top">
          <Col>
            <Button variant="success" onClick={() => { navigate('/users/add', {state: {page: page, query: query}}); }}><DynamicIcon iconName='BiPlus' /> Nuovo utente</Button>
          </Col>
        </Row>
        {/* <Row>
          <Col style={{margin:'0 20px'}}>
            <form className="custom_form" id="query_form">
              <DynamicIcon iconName='FaSearch' />
              <input type="text" placeholder="Cerca utente" id="query" onChange={(e) => { handleInputChange(e.target.value) }} />
            </form>
          </Col>
        </Row> */}
        <Row>
          <Col className='tab_wrapper'>
            { users ? 
              <>
                <Table responsive>
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Nome</th>
                      <th>Ruolo</th>
                      <th>E-mail</th>
                      <th>Data registrazione</th>
                      <th style={{'width':'120px'}}></th>
                    </tr>
                  </thead>
                  <tbody>
                    { users.length ? users.map((user) => {
                        return <tr key={ user.id }>
                                <td>{ user.id }</td>
                                <td>{ user.etichetta }</td>
                                <td>{ user.role.descrizione }</td>
                                <td>{ user.email }</td>
                                <td>{ user.created_at ? user.created_at.substr(0, 10).split("-").reverse().join("/") : '-' }</td>
                                <td className="actions">
                                  <Button onClick={() => { navigate('/users/add', {state: {user_id: user.id, page: page, query: query}}) }}><DynamicIcon iconName='IoIosArrowForward' /></Button>
                                  <Button onClick={() => { handleDelete(user.id) }} className="delete"><DynamicIcon iconName='FaTrash' /></Button>
                                </td>
                              </tr>
                      }) : '' }
                  </tbody>
                </Table>
                { numPages > 1 ?
                  <div className='pageListWrapper'>
                    <ul className='pagesList'>
                      { [...Array(numPages)].map(function(obj, i) {
                        let ii = i+1;
                        if (numPages > 5)
                          switch (parseInt(ii)) {
                            case 1:
                            case (page-1):
                            case page:
                            case page+1:
                            case numPages:
                              return <li key={'pag'+ii} className={ ii == page ? 'active' : '' } onClick={() => { getPage(ii, query) }}>{ii}</li>
                            default:
                              return '';
                          }
                        else
                          return <li key={'pag'+ii} className={ ii == page ? 'active' : '' } onClick={() => { getPage(ii, query) }}>{ii}</li>
                      }) }
                    </ul>
                  </div>
                : '' }
              </>
            : <p style={{textAlign:'center'}}>Nessuna anagrafica presente</p> }
          </Col>
        </Row>
      </Container>
    </>
  );
  
}

export default Users;