import React, { useState, useEffect, useContext } from 'react';
// AUTH
import { AuthContext } from '../contexts/AuthContext';
// REACT ROUTER
import { useNavigate, useLocation } from 'react-router-dom';
// AXIOS
import axios from 'axios';
// REACT HOOK FORM
import { useForm } from "react-hook-form";
// BOOTSTRAP
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
// REACT SPINNER
import { Watch } from 'react-loader-spinner';
// REACT SWEETALERT
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
// DYNAMIC ICON
import DynamicIcon from '../partials/DynamicIcon';

function Permissions() {
  // AUTH, STATE, NAVIGATION
  const { authobj } = useContext(AuthContext);
  const { state } = useLocation();
  let navigate = useNavigate();
  // FORM
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  // SWEETALERT
  const MySwal = withReactContent(Swal);
  // DATA
  const [role, setRole] = useState(null);
  const [permissions, setPermissions] = useState(null);
  const [permissionGroups, setPermissionGroups] = useState([]);
  const [checks, setChecks] = useState([]);
  // SPINNER
  const [wait, setWait] = useState(false);
  const [waitPercent, setWaitPercent] = useState(null);

  useEffect(() => {
    setWait(true);

    // Se sto modificando un ruolo esistente, carico le informazioni relative
    if (state && state.role_id) {
      axios.post(window.SERVER_URL+'roles/get', { id: state.role_id }, { headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer '+authobj.token
      }}).then(res => {
        setRole(res.data.role);

        axios.post(window.SERVER_URL+'permissions/list', { role_id: state.role_id }, { headers: {
          'Access-Control-Allow-Origin': '*',
          'Authorization': 'Bearer '+authobj.token
        }}).then(res => {
          console.log(res.data);
          setPermissions(res.data.permissions);
          setPermissionGroups(Object.keys(res.data.permissions));

          let fields = {};
          Object.keys(res.data.permissions).map((group) => {
            res.data.permissions[group].map((item) => {
              if (item.accesso == '1')
                fields['perm_'+item.id] = (item.accesso == '1' ? true : false);
            });
          });
          reset(fields);
          console.log(fields);

          setWait(false);
        });
      });
    } else {
      setWait(false);

      Swal.fire({
        icon: 'error',
        title: 'Errore',
        text: "Devi selezionare un ruolo per settare i suoi permessi"
      });
    }
  }, []);

  // Submit della form
  const onSubmit = (form_data) => {
    console.log(form_data);
    axios.post(window.SERVER_URL+'permissions/save', { 'role_id': state.role_id, 'permissions': form_data }, { headers: {
      'Access-Control-Allow-Origin': '*',
      'Authorization': 'Bearer '+authobj.token
    }}).then(res => {
      console.log(res);
      if (res.data.success) {
        Swal.fire({
          icon: 'success',
          title: 'Permessi salvati',
          text: 'Permessi salvati correttamente'
        }).then((result) => {
          if (result.isConfirmed) {
            navigate('/roles');
          }
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Errore',
          text: "Non è stato possibile aggiornare i permessi del ruolo "+role.descrizione
        });
      }
    });
  }
  
  return (
    <>
      { wait ? 
        <div id="wait">
          { waitPercent !== null ?
            <div className="percent">{waitPercent}%</div>
          : '' }
          <Watch height="80" width="80" radius="48" color="#44B2EB" ariaLabel="watch-loading" wrapperStyle={{}} wrapperClassName="" visible={true} />
        </div>
      : '' }
      <Container className="content">
        { role ? 
          <>
            <Row className="page_title">
              <Col>
                <h1>Permessi ruolo { role.descrizione }</h1>
              </Col>
            </Row>
            <Row>
              <Col>
                <form className="custom_form" onSubmit={handleSubmit(onSubmit)}>
                  { permissions && permissionGroups ?
                    permissionGroups.map((group) => {
                      return <>
                              <h3>{ group }</h3>
                              <ul>
                              { permissions[group].map((item) => {
                                return <li>
                                          <label key={'perm_'+item.id} for={'perm_'+item.id}>
                                            <input type="checkbox" name={'perm_'+item.id} id={'perm_'+item.id} {...register('perm_'+item.id)} /> &nbsp;
                                            <b>{ item.permission.etichetta }</b>
                                          </label>
                                          <br />
                                          <i>{ item.permission.descrizione }</i>
                                        </li>
                              }) }
                              </ul>
                            </>
                    })
                  : '' }
                  <Button type="submit" variant="primary" className="waste_btn">Salva</Button>
                </form>
              </Col>
            </Row>
          </>
        : '' }
        <Row className="action_bar bottom">
          <Col>
            <Button onClick={() => navigate('/roles', { state: { page: (state && state.page ? state.page : 1), query: (state && state.query ? state.query : null) } })}><DynamicIcon iconName='IoIosArrowBack' /> Torna ai ruoli</Button>
          </Col>
        </Row>
      </Container>
    </>
  );
  
}

export default Permissions;