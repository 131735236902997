import React, { useContext, useEffect, useState } from 'react';
// AUTH
import { AuthContext } from '../contexts/AuthContext';
// REACT ROUTER
import { useNavigate, useLocation } from 'react-router-dom';
// AXIOS
import axios from 'axios';
// REACT HOOK FORM
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message"
// BOOTSTRAP
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
// REACT SPINNER
import { Watch } from 'react-loader-spinner';
// REACT SWEETALERT
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
// DYNAMIC ICON
import DynamicIcon from '../partials/DynamicIcon';

function TechniciansAdd() {
  // AUTH, STATE, NAVIGATION
  const { authobj } = useContext(AuthContext);
  const { state } = useLocation();
  let navigate = useNavigate();
  // FORM
  const { register, handleSubmit, reset, getValues, formState: { errors } } = useForm();
  // SWEETALERT
  const MySwal = withReactContent(Swal);
  // DATA
  const [technician, setTechnician] = useState(null);
  const [provinces, setProvinces] = useState([]);
  const [scopes, setScopes] = useState([]);
  const [files, setFiles] = useState([]);
  const [zone, setZone] = useState(null);
  const [selectedZones, setSelectedZones] = useState([]);
  const [allZones, setAllZones] = useState({});
  // SPINNER
  const [wait, setWait] = useState(false);
  const [waitPercent, setWaitPercent] = useState(null);

  useEffect(() => {
    setWait(true);

    // Carico le province
    axios.post(window.SERVER_URL+'provinces/list', { }, { headers: {
      'Access-Control-Allow-Origin': '*',
      'Authorization': 'Bearer '+authobj.token
    }}).then(res => {
      setProvinces(res.data.provinces);

      const updatedZones = {};
      res.data.provinces.forEach((prov) => {
        updatedZones[prov.id] = prov.nome;
      });
      setAllZones(updatedZones);

      axios.post(window.SERVER_URL+'technician_files/list', null, { headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer '+authobj.token
      }}).then(res => {
        console.log(res.data.files);
        setFiles(res.data.files);

        axios.post(window.SERVER_URL+'technician_scopes/list', null, { headers: {
          'Access-Control-Allow-Origin': '*',
          'Authorization': 'Bearer '+authobj.token
        }}).then(res => {
          console.log(res.data.scopes);
          setScopes(res.data.scopes);

          // Se sto modificando una tecnico esistente, carico le informazioni relative
          if (state && state.technician_id) {
            axios.post(window.SERVER_URL+'technicians/get', { id: state.technician_id }, { headers: {
              'Access-Control-Allow-Origin': '*',
              'Authorization': 'Bearer '+authobj.token
            }}).then(res => {
              console.log(res.data);
              setTechnician(res.data.technician);

              // Setto i campi della form
              let fields = {};
              for (const [key, val] of Object.entries(res.data.technician))
                if (typeof val !== 'object')
                  // if (typeof(getValues().user[key]) !== 'undefined')
                    fields['user['+key+']'] = val;
              for (const [key, val] of Object.entries(res.data.technician.profile))
                if (typeof val !== 'object')
                  // if (typeof(getValues().profile[key]) !== 'undefined')
                    fields['profile['+key+']'] = val;
              fields['user[password]'] = '**********';
              console.log(fields);
              reset(fields);

              if (res.data.technician && res.data.technician.zone)
                setSelectedZones(res.data.technician.zone);

              setWait(false);
            });
          } else {
            setWait(false);
          }
        });
      });
    });
  }, [reset]);

  const handleSelectZone = (event) => {
    let prov_id = event.target.value;
    let prov_name = event.target.options[event.target.selectedIndex].text;
    setZone(prov_id);
  }

  const handleAddZone = () => {
    if (zone) {
      setSelectedZones((prevZones) => prevZones.includes(zone) ? prevZones : [...prevZones, zone]);
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Errore',
        text: "Devi selezionare una provincia per aggiungerla alla lista"
      });
    }
  }

  const handleRemoveZone = (prov_id) => {
    setSelectedZones((prevSelectedZones) => 
      prevSelectedZones.filter(id => id !== prov_id)
    );
  }
  
  // Submit della form
  const onSubmit = (form_data) => {
    console.log(form_data);

    const formData = new FormData();
    for (const [key, value] of Object.entries(form_data.user))
      formData.append('user['+key+']', value);
    for (const [key, value] of Object.entries(form_data.profile))
      formData.append('profile['+key+']', value);
    if (form_data.file) {
      for (const [key, value] of Object.entries(form_data.file)) {
        formData.append('files['+key+'][file]', value.file[0]);
        formData.append('files['+key+'][scadenza]', value.scadenza);
      }
    }
    if (state.technician_id)
      formData.append('user[id]', state.technician_id);
    formData.append('user[zone]', JSON.stringify(selectedZones ? selectedZones: []));

    console.log(formData);

    axios.post(window.SERVER_URL+'technicians/save', formData, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer '+authobj.token,
        'Content-Type': 'multipart/form-data'
      }
    }).then(res => {
      if (res.data.success) {
        Swal.fire({
          icon: 'success',
          title: 'Tecnico salvato',
          text: 'Tecnico salvato correttamente'
        }).then((result) => {
          if (result.isConfirmed) {
            if (authobj.role.toLowerCase() == 'superadmin')
              navigate('/technicians');
            else
              navigate('/dashboard');
          }
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Errore',
          text: "Non è stato possibile salvare il tecnico"
        });
      }
    }).catch(function(error) {
      console.log(error);
      Swal.fire({
        icon: 'error',
        title: 'Errore',
        text: "Non è stato possibile salvare il tecnico"
      });
    });
  }

  return (
    <>
      { wait ? 
        <div id="wait">
          { waitPercent !== null ?
            <div className="percent">{waitPercent}%</div>
          : '' }
          <Watch height="80" width="80" radius="48" color="#44B2EB" ariaLabel="watch-loading" wrapperStyle={{}} wrapperClassName="" visible={true} />
        </div>
      : '' }
      <Container className="content">
        <Row className="page_title">
          <Col>
            <h1>Aggiungi o modifica tecnico</h1>
          </Col>
        </Row>
        <Row>
          <Col className="custom_form_wrapper">
            <form className="custom_form" onSubmit={handleSubmit(onSubmit)}>
              <fieldset style={{'background':'#f8f8f8','padding':'5px 20px 20px'}}>
                <label className="fieldset_label"><DynamicIcon iconName="FaUser" size="20" spaced /> INFORMAZIONI ANAGRAFICHE</label>
                <Row>
                  <Col>
                    <label>Nome</label>
                    <input type="text" placeholder="Nome" {...register("user[nome]", { required: "Campo obbligatorio" })} />
                    <ErrorMessage errors={errors} name="user[nome]" render={({ message }) => <p className="form_error">{message}</p>} />
                  </Col>
                  <Col>
                    <label>Cognome</label>
                    <input type="text" placeholder="Cognome" {...register("user[cognome]", { required: "Campo obbligatorio" })} />
                    <ErrorMessage errors={errors} name="user[cognome]" render={({ message }) => <p className="form_error">{message}</p>} />
                  </Col>
                  <Col>
                    <label>Perimetro di intervento</label>
                    <select {...register("profile[technician_scope_id]", { required: "Campo obbligatorio" })}>
                      <option value="">- Seleziona un ambito -</option>
                      { scopes && scopes.length ? scopes.map((scope) => {
                          return <option key={ scope.id } value={ scope.id }>{ scope.descrizione }</option>
                      }) : ''}
                    </select>
                    <ErrorMessage errors={errors} name="profile[technician_scope_id]" render={({ message }) => <p className="form_error">{message}</p>} />
                  </Col>
                </Row>
                { technician ?
                  <Row>
                    <Col>
                      <label>Data di nascita</label>
                      <input type="date" placeholder="Data di nascita" {...register("profile[data_nascita]", { required: false })} />
                    </Col>
                    <Col>
                      <label>Codice fiscale</label>
                      <input type="text" placeholder="Codice fiscale" {...register("profile[codice_fiscale]", { required: false })} />
                    </Col>
                  </Row>
                : '' }
              </fieldset>
              <fieldset style={{'background':'#eee','padding':'5px 20px 20px','marginTop':'20px'}}>
                <label className="fieldset_label"><DynamicIcon iconName="BsTelephoneFill" size="20" spaced /> INFORMAZIONI DI CONTATTO</label>
                <Row>
                  <Col>
                    <label>Telefono</label>
                    <input type="text" placeholder="Telefono" {...register("profile[telefono]", { required: "Campo obbligatorio" })} />
                    <ErrorMessage errors={errors} name="profile[telefono]" render={({ message }) => <p className="form_error">{message}</p>} />
                  </Col>
                  <Col>
                    <label>E-mail</label>
                    <input type="email" placeholder="E-mail" {...register("profile[email]", { required: "Campo obbligatorio" })} />
                    <ErrorMessage errors={errors} name="profile[email]" render={({ message }) => <p className="form_error">{message}</p>} />
                  </Col>
                </Row>
              </fieldset>
              <fieldset style={{'background':'#f8f8f8','padding':'5px 20px 20px','marginTop':'20px'}}>
                <label className="fieldset_label"><DynamicIcon iconName="FaKey" size="20" spaced /> INFORMAZIONI DI ACCESSO</label>
                <Row>
                  <Col>
                    <label>E-mail</label>
                    <input type="email" placeholder="E-mail" {...register("user[email]", { required: "Campo obbligatorio" })} />
                    <ErrorMessage errors={errors} name="user[email]" render={({ message }) => <p className="form_error">{message}</p>} />
                  </Col>
                  <Col>
                    <label>Password</label>
                    <input type="password" placeholder="Password" {...register("user[password]", { required: "Campo obbligatorio" })} />
                    <ErrorMessage errors={errors} name="user[password]" render={({ message }) => <p className="form_error">{message}</p>} />
                  </Col>
                </Row>
              </fieldset>
              { technician ?
                <>
                  <fieldset style={{'background':'#eee','padding':'5px 20px 20px','marginTop':'20px'}}>
                    <label className="fieldset_label"><DynamicIcon iconName="FaEuroSign" size="20" spaced /> DATI DI FATTURAZIONE</label>
                    <Row>
                      <Col>
                        <label>Ragione sociale</label>
                        <input type="text" placeholder="Ragione sociale" {...register("profile[ragione_sociale]", { required: false })} />
                      </Col>
                      <Col>
                        <label>Partita IVA</label>
                        <input type="text" placeholder="Partita IVA" {...register("profile[partita_iva]", { required: false })} />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <label>Comune</label>
                        <input type="text" placeholder="Comune" {...register("profile[comune]", { required: false })} />
                      </Col>
                      <Col>
                        <label>Provincia</label>
                        <select {...register("profile[province_id]")}>
                          <option value="">- Seleziona la provincia -</option>
                          { provinces ? provinces.map((prov) => 
                            <option key={ prov.id } value={ prov.id }>{ prov.nome }</option>
                          ) : '' }
                        </select>
                      </Col>
                      <Col>
                        <label>Stato</label>
                        <input type="text" placeholder="Stato" {...register("profile[stato]", { required: false })} />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <label>CAP</label>
                        <input type="text" placeholder="CAP" {...register("profile[cap]", { required: false })} />
                      </Col>
                      <Col>
                        <label>Indirizzo</label>
                        <input type="text" placeholder="Indirizzo" {...register("profile[indirizzo]", { required: false })} />
                      </Col>
                      <Col>
                        <label>Num. civico</label>
                        <input type="text" placeholder="Civico" {...register("profile[civico]", { required: false })} />
                      </Col>
                    </Row>
                  </fieldset>
                  <fieldset style={{'background':'#f8f8f8','padding':'5px 20px 20px','marginTop':'20px'}}>
                    <label className="fieldset_label"><DynamicIcon iconName="FaMapMarkerAlt" size="20" spaced /> COMPETENZA GEOGRAFICA</label>
                    <Row>
                      <Col>
                          <br /><p><b>Aggiungi tutte le province in cui vuoi/puoi lavorare; ogni modifica verrà sottoposta a verifica da parte dell'amministrazione.</b></p>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <label>Provincia</label>
                        <select style={{'width':'300px','display':'inline-block','margin':'0 15px'}} onChange={handleSelectZone}>
                          <option value="">- Seleziona la provincia -</option>
                          { provinces ? provinces.map((prov) => 
                            <option key={ 'GEO'+prov.id } value={ prov.id }>{ prov.nome }</option>
                          ) : '' }
                        </select>
                        <Button onClick={() => { handleAddZone() }}>Aggiungi</Button>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                          <ul className='tag_list'>
                          { selectedZones && selectedZones.length ? selectedZones.map(prov_id =>
                            <li>
                              { allZones[prov_id] }
                              <span onClick={() => handleRemoveZone(prov_id)}><DynamicIcon iconName="TiDelete" size="20" /></span>
                            </li>
                          ) : '' }
                          </ul>
                      </Col>
                    </Row>
                  </fieldset>
                  <fieldset style={{'background':'#eee','padding':'5px 20px 20px','marginTop':'20px'}}>
                    <label className="fieldset_label"><DynamicIcon iconName="FaGraduationCap" size="20" spaced /> ISCRIZIONE ORDINE</label>
                    <Row>
                      <Col>
                        <label>Data iscrizione all'ordine</label>
                        <input type="date" placeholder="Data iscrizione" {...register("profile[ordine_data_iscrizione]", { required: false })} />
                      </Col>
                      <Col>
                        <label>Ordine di iscrizione</label>
                        <input type="text" placeholder="Ordine" {...register("profile[ordine_nome]", { required: false })} />
                      </Col>
                      <Col>
                        <label>Numero/Codice di iscrizione</label>
                        <input type="text" placeholder="Numero o codice iscrizione" {...register("profile[ordine_num_iscrizione]", { required: false })} />
                      </Col>
                    </Row>
                  </fieldset>
                  <fieldset style={{'background':'#f8f8f8','padding':'5px 20px 20px','marginTop':'20px'}}>
                    <label className="fieldset_label"><DynamicIcon iconName="FaFolderOpen" size="20" spaced /> DOCUMENTI</label>
                    { files && files.length ? files.map((f) => {
                      return <>
                              <Row>
                                <Col>
                                  <label><b>{ f.descrizione }</b></label>
                                  { technician && technician.files && technician.files[f.id] ?
                                    <p style={{'display':'inline-block','marginLeft':'40px'}}>
                                      <img src={`${process.env.PUBLIC_URL}/gfx/Iconly-Light-Paper.png`} style={{'display':'inline-block','marginRight':'10px'}} />
                                      File attuale: <a target="_blank" href={ technician.files[f.id].link }>
                                                      { technician.files[f.id].nome }
                                                    </a>
                                                    &nbsp;
                                                    ({ technician.files[f.id].peso })
                                                    { technician.files[f.id].scadenza ?
                                                      <span> - Scadenza: { technician.files[f.id].scadenza }</span>
                                                    : '' }
                                    </p>
                                  : '' }
                                </Col>
                              </Row>
                              <Row style={{'paddingBottom':'20px','marginBottom':'20px','borderBottom':'1px solid #ddd'}}>
                                <Col>
                                  <label>Carica nuovo file</label>
                                  <input type="file" name={'file['+f.id+'][file]'} {...register('file['+f.id+'][file]', { required: false })} />
                                </Col>
                                { f.scadenza == 1 ?
                                  <Col>
                                    <label>Data scadenza nuovo file</label>
                                    <input type="date" name={'file['+f.id+'][scadenza]'} {...register('file['+f.id+'][scadenza]', { required: false })} />
                                  </Col>
                                : '' }
                              </Row>
                            </>
                    }) : <p>Non ci sono documenti da caricare</p> }
                  </fieldset>
                </>
              : '' }
              <Button type="submit" variant="primary" className="waste_btn">Salva</Button>
            </form>
          </Col>
        </Row>
        <Row className="action_bar bottom">
          <Col>
            <Button onClick={() => navigate('/technicians', { state: { page: (state && state.page ? state.page : 1), query: (state && state.query ? state.query : null) } })}><DynamicIcon iconName='IoIosArrowBack' /> Torna agli utenti</Button>
          </Col>
        </Row>
      </Container>
    </>
  );
  
}

export default TechniciansAdd;