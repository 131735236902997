import React, { useState, useEffect, useContext } from 'react';
// AUTH
import { AuthContext } from '../contexts/AuthContext';
// REACT ROUTER
import { useNavigate, useLocation } from 'react-router-dom';
// AXIOS
import axios from 'axios';
// BOOTSTRAP
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
// REACT SWEETALERT
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
// DYNAMIC ICON
import DynamicIcon from '../partials/DynamicIcon';

function Customers() {
  // AUTH, STATE, NAVIGATION
  const { authobj } = useContext(AuthContext);
  const { state } = useLocation();
  let navigate = useNavigate();
  // SWEETALERT
  const MySwal = withReactContent(Swal);
  // DATA
  const [customers, setCustomers] = useState(null);
  const [page, setPage] = useState(1);
  const [numPages, setNumPages] = useState(1);
  const [count, setCount] = useState(null);
  const [query, setQuery] = useState(null);

  useEffect(() => {
    // document.getElementById('query').value = state && state.query ? state.query : query;
    getPage(
      state && state.page ? state.page : page,
      state && state.query ? state.query : query
    );
  }, []);

  const getPage = (i, query) => {
    let params = {
      page: i
    }
    if (query && query.length >= 3)
      params.search = query;
      
    axios.post(window.SERVER_URL+'customers/list', { params }, { headers: {
      'Access-Control-Allow-Origin': '*',
      'Authorization': 'Bearer '+authobj.token
    }}).then(res => {
      console.log(res.data);
      setCustomers(res.data.customers);
      setPage(i);
      setNumPages(res.data.pages);
      setCount(res.data.count);
      setQuery(query);
    });
  }

  const handleInputChange = (typed) => {
    console.log(typed);
    getPage(1, typed);
  }

  const handleDelete = (customer_id) => {
    Swal.fire({
      icon: 'warning',
      title: "Vuoi davvero cancellare il cliente?",
      html: "<b>ATTENTO!</b> L'operazione non è reversibile.",
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: 'Cancella',
      // denyButtonText: 'Annulla',
      cancelButtonText: 'Annulla',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
    }).then((result) => {
      if (result.isConfirmed) {
        axios.post(window.SERVER_URL+'customers/delete', { id: customer_id }, { headers: {
          'Access-Control-Allow-Origin': '*',
          'Authorization': 'Bearer '+authobj.token
        }}).then(res => {
          if (res.data.success === true) {
            // se la cancellazione è andata a buon fine
            setCustomers(customers.filter(customer => customer.id !== customer_id));
            getPage(1, query);
            Swal.fire({
              icon: 'success',
              title: 'Cliente cancellato',
              text: 'Cliente cancellato correttamente'
            });
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Errore',
              text: "Non è stato possibile cancellare il cliente"
            });
          }
        });
      }
    });
  }
  
  return (
    <>
      <Container className="content">
        <Row className="page_title">
          <Col>
            <h1>Clienti</h1>
          </Col>
        </Row>
        <Row className="action_bar top">
          <Col>
            <Button variant="success" onClick={() => { navigate('/customers/add', {state: {page: page, query: query}}); }}><DynamicIcon iconName='BiPlus' /> Nuovo cliente</Button>
          </Col>
        </Row>
        {/* <Row>
          <Col style={{margin:'0 20px'}}>
            <form className="custom_form" id="query_form">
              <DynamicIcon iconName='FaSearch' />
              <input type="text" placeholder="Cerca cliente" id="query" onChange={(e) => { handleInputChange(e.target.value) }} />
            </form>
          </Col>
        </Row> */}
        <Row>
          <Col className='tab_wrapper'>
            { customers ? 
              <>
                <Table responsive>
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Nome</th>
                      <th>P.IVA</th>
                      <th>Cod.fiscale</th>
                      <th>Comune</th>
                      <th>E-mail</th>
                      <th>Data registrazione</th>
                      <th style={{'width':'120px'}}></th>
                    </tr>
                  </thead>
                  <tbody>
                    { customers.length ? customers.map((customer) => {
                        return <tr key={ customer.id }>
                                <td>{ customer.id }</td>
                                <td>{ customer.etichetta }</td>
                                <td>{ customer.profile.partita_iva }</td>
                                <td>{ customer.profile.codice_fiscale }</td>
                                <td>{ customer.profile.comune }</td>
                                <td>{ customer.email }</td>
                                <td>{ customer.created_at ? customer.created_at.substr(0, 10).split("-").reverse().join("/") : '-' }</td>
                                <td className="actions">
                                  <Button onClick={() => { navigate('/customers/add', {state: {customer_id: customer.id, page: page, query: query}}) }}><DynamicIcon iconName='IoIosArrowForward' /></Button>
                                  <Button onClick={() => { handleDelete(customer.id) }} className="delete"><DynamicIcon iconName='FaTrash' /></Button>
                                </td>
                              </tr>
                      }) : '' }
                  </tbody>
                </Table>
                { numPages > 1 ?
                  <div className='pageListWrapper'>
                    <ul className='pagesList'>
                      { [...Array(numPages)].map(function(obj, i) {
                        let ii = i+1;
                        if (numPages > 5)
                          switch (parseInt(ii)) {
                            case 1:
                            case (page-1):
                            case page:
                            case page+1:
                            case numPages:
                              return <li key={'pag'+ii} className={ ii == page ? 'active' : '' } onClick={() => { getPage(ii, query) }}>{ii}</li>
                            default:
                              return '';
                          }
                        else
                          return <li key={'pag'+ii} className={ ii == page ? 'active' : '' } onClick={() => { getPage(ii, query) }}>{ii}</li>
                      }) }
                    </ul>
                  </div>
                : '' }
              </>
            : <p style={{textAlign:'center'}}>Nessuna anagrafica presente</p> }
          </Col>
        </Row>
      </Container>
    </>
  );
  
}

export default Customers;